import { createApi } from '@reduxjs/toolkit/query/react'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { CBFOffersListItem, OfferPreview, Offer, OfferOfflineRaw, OfferType } from '~/models'
import {
  CashbackTransaction,
  NewOffersData,
  NewOffersRawData,
  OfferFinal,
  OffersCategory,
  TotalCashbackData,
} from '~/components/newoffers/types'
import { getFetchConfigParamsData } from './utils/getFetchConfigParamsData'

const axiosRTQuery = createAxiosRTQuery()

interface QueryOfferData {
  offer: Offer
}

export const offersApi = createApi({
  reducerPath: 'offers',
  baseQuery: axiosRTQuery({
    baseUrl: '/',
  }),
  tagTypes: ['OfflineOffers', 'NewOffers'],
  endpoints: (build) => ({
    getNewOffers: build.query<NewOffersData, { categoryId?: string; page?: number; elements?: number } | void>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profileId, bankMnemocode } = getFetchConfigParamsData(queryApi)

        const defaultPage = 0
        // at one of the meetings we came to conclusion that there will be no more than 300 offers in total
        const defaultElements = 300
        const page = args?.page ?? defaultPage
        const elements = args?.elements ?? defaultElements

        const res = args?.categoryId
          ? await fetchWithBaseQuery({
              url: `clo/offers/by-category`,
              method: 'GET',
              params: {
                categoryId: args.categoryId,
                profileId,
                page,
                elements,
                partnerMnemocode: bankMnemocode,
              },
            })
          : await fetchWithBaseQuery({
              url: `clo/offers/clients/v2`,
              method: 'GET',
              params: {
                profileId,
                page,
                elements,
                partnerMnemocode: bankMnemocode,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        const error = resError?.status

        if (error) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as NewOffersRawData
        const fetchedOffers: OfferFinal[] = resData.offers.map((offer) => {
          return {
            id: offer.id,
            isVerified: offer.isForAll,
            channel: offer.type === 'ONLINE' ? 'online' : 'offline',
            profitType: 'cashback',
            profitMethod: offer.type === 'ONLINE' ? 'referralLink' : 'paymentCard',
            link:
              offer.type === 'ONLINE'
                ? `/gp-api-gateway/clo/epn/link/generate?profileId=${profileId}&partnerMnemocode=${bankMnemocode}&offerId=${offer.id}`
                : null,
            // missing from original interface
            currency: 'rub',
            logoUrl: offer.iconUrl!,
            source: offer.offerSource === 'FILE' ? 'internal' : 'external',
            description: offer.data.description,
            value: Number(offer.data.payment_size),
            valueType: offer.data.currency === '%' ? 'percentage' : 'fixed',
            isActivated: offer.isActivated,
            isActivationRequired: Boolean(offer.isActivationRequired),
            startDate: offer.startAt,
            endDate: offer.stopAt,
            additionalRules: offer.data.additional_rules,
            averageWaitTime: offer.data.hold_time,
            displayStartDate: offer.displayDate,
            // It's either in client timezone (local) or in UTC timezone
            isDisplayStartDateLocal: offer.isProlongation,
            categoryIds: offer.offerCategories,
            aboutLink: '',
            merchant: {
              name: offer.name,
              description: offer.partnerShortDescription,
              addresses: offer.data.address,
              link: offer.www,
            },
          }
        })

        const now = new Date()
        const offers = fetchedOffers
          .filter((offer) => offer.isVerified)
          .filter((offer) => {
            if (!offer.displayStartDate) return true

            // displayStartDate has a format of "2024-09-20T00:00:00", in order to let JS know that it's in UTC we have to add the Z letter
            const displayStartDate = new Date(offer.displayStartDate + (offer.isDisplayStartDateLocal ? '' : 'Z'))

            return now > displayStartDate
          })

        return { data: { offers } }
      },
      providesTags: ['NewOffers'],
    }),
    getTotalCashback: build.query<TotalCashbackData, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode } = getFetchConfigParamsData(queryApi)
        const res = await fetchWithBaseQuery({
          url: `00000001/v2/microservice/processing/balance/offers/total/profile`,
          method: 'GET',
          params: {
            profile_code: profile_mnemocode,
          },
        })

        const resError = res.error as { status: number; data: unknown }
        const error = resError?.status
        if (error) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as TotalCashbackData

        return { data: resData }
      },
    }),
    getOffersCategories: build.query<OffersCategory[], void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { bankMnemocode } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `clo/categories/filled`,
          method: 'GET',
          params: {
            partnerMnemocode: bankMnemocode,
          },
        })

        const resError = res.error as { status: number; data: unknown }
        const error = resError?.status
        if (error) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as OffersCategory[]

        return { data: resData }
      },
    }),
    getCashbackHistory: build.query<CashbackTransaction[], void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `00000001/v2/microservice/processing/balance/offers/history/profile`,
          method: 'GET',
          params: {
            profile_code: profile_mnemocode,
          },
        })

        const resError = res.error as { status: number; data: unknown }
        const error = resError?.status
        if (error) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as CashbackTransaction[]

        return { data: resData }
      },
    }),
    getCBFOffers: build.query<OfferPreview[], void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { uuid } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `brothers/api/offer/offers?uuid=${uuid}`,
          method: 'GET',
        })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as { status: string; data: CBFOffersListItem[] }
        const data: OfferPreview[] = resData.data
          .filter((el) => el?.type === 'online')
          .map(({ id, offer, type, icon, currency }) => ({
            hashCode: id,
            type,
            icon,
            isActivated: false,
            data: {
              payment_size: offer,
              currency,
            },
          }))
        return { data }
      },
    }),
    getClientsOffers: build.query<Offer[], void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profileId, bankMnemocode } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `gp-api-gateway/clo/offers/clients?profileId=${profileId}&partnerMnemocode=${bankMnemocode}`,
          method: 'GET',
        })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const dataRaw = res.data as unknown as OfferOfflineRaw[]

        const data = dataRaw.map((offer) => {
          const icon = offer.iconUrl!
          const type = offer.type.toLowerCase() as OfferType
          delete offer.iconUrl
          return { ...offer, icon, type }
        })

        return { data }
      },
      providesTags: ['OfflineOffers'],
    }),
    getCBFSingleOffer: build.query<Offer, string>({
      async queryFn(arg, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { uuid } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `brothers/api/offer/offers/offer/${arg}?uuid=${uuid}`,
          method: 'GET',
        })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as { status: string; data: QueryOfferData }
        const data = resData.data.offer
        data.hashCode = arg

        return { data }
      },
    }),
    activateOffer: build.mutation<null, string>({
      async queryFn(arg, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profileId, bankMnemocode } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `gp-api-gateway/clo/offers/activate`,
          method: 'POST',
          data: {
            bankMnemocode,
            profileId,
            offerId: Number(arg),
          },
        })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        return { data: null }
      },
      invalidatesTags: ['OfflineOffers', 'NewOffers'],
    }),
    getUserToken: build.query<string, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { sessionToken } = getFetchConfigParamsData(queryApi)

        const res = await fetchWithBaseQuery({
          url: `gp-api-gateway/auth/user-token?token=${sessionToken}`,
          method: 'GET',
        })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const data = (res.data as any).user_token as unknown as string
        return { data }
      },
    }),
  }),
})

export const {
  useGetCBFOffersQuery,
  useGetClientsOffersQuery,
  useGetCBFSingleOfferQuery,
  useActivateOfferMutation,
  useGetUserTokenQuery,
  useGetTotalCashbackQuery,
  useGetCashbackHistoryQuery,
  useGetNewOffersQuery,
  useGetOffersCategoriesQuery,
} = offersApi
