import { productApi } from './api/products'
import { programApi } from './api/programs'
import { entriesApi } from './api/entries'
import { offersApi } from './api/offers'
import { profileApi } from './api/profile'
import { balanceApi } from './api/balance'
import { miscApi } from './api/misc'
import { optionsApi } from './api/options'

export const apiMiddleware = [
  productApi.middleware,
  programApi.middleware,
  entriesApi.middleware,
  offersApi.middleware,
  profileApi.middleware,
  balanceApi.middleware,
  miscApi.middleware,
  optionsApi.middleware,
]
