import { useSelector } from 'react-redux'
import { useTypedSelector } from '~/store/redux-store'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useGetProducts } from '~/hooks/useGetProducts'
import { allOptionsSelector, optionsByProgramSelector } from '~/store/api/options'

export function useGetDefaultOption() {
  const byProgram = useSelector(optionsByProgramSelector)
  const options = useSelector(allOptionsSelector)
  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)
  const selectedProductOption = useTypedSelector(({ process }) => process.selectedProductOption)

  const program = useGetPrograms(selectedProgram as string)
  const product = useGetProducts(selectedCard as string)
  const programEntryClass = program?.entryClass
  const productEntryClass = product?.entryClass
  const programs = byProgram[programEntryClass]
  let _selectedProductOption = 0
  if (!selectedProductOption) {
    _selectedProductOption = options[0]?.optionId
  } else {
    _selectedProductOption = selectedProductOption
  }
  const neededProgram = programs?.find(
    (currentProgram) =>
      currentProgram?.optionId === _selectedProductOption && currentProgram?.productClass === productEntryClass
  )
  return neededProgram?.product_option_id
}
