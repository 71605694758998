import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { BaseQueryApi } from '@reduxjs/toolkit/query'

import type { RootState } from '~/store/redux-store'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import type { MaybePromise } from '@reduxjs/toolkit/src/query/tsHelpers'
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import { PartnerBanks } from '~/models'
import { errorHandlerRegular } from '~/lib/api/handlers/errorHandlerRegular'

interface Params {
  token?: string
}

interface FetchParams {
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
  paramsSerializer?: AxiosRequestConfig['paramsSerializer']
}

let apiClient: AxiosInstance | undefined

export const shouldUseGatewayApi = (company: PartnerBanks) => {
  const isGatewayApiEnabled = localStorage.getItem('is-gateway-api-enabled')
  // const isGatewayApiFallbackEnabled = localStorage.getItem('is-gateway-api-fallback-enabled')

  return isGatewayApiEnabled === 'true'
}

export const getGatewayApiUrlFromQueryApi = (queryApi: BaseQueryApi) => {
  const { getState } = queryApi
  const state = getState() as RootState
  const { gatewayApiUrl } = whiteLabelSelector(state)
  return gatewayApiUrl
}

const gatewayApi = ({ token }: Params = {}): AxiosInstance => {
  if (apiClient) return apiClient

  const httpConfig: Partial<AxiosRequestConfig> = {
    responseType: 'json',
    baseURL: '',
    timeout: 15000,
  }
  const axiosInstance = axios.create(httpConfig)

  axiosInstance.interceptors.request.use((config) => {
    const tokenFinal = token ?? localStorage.getItem('token-new')

    if (tokenFinal) config.headers.authorization = `Bearer ${tokenFinal}`

    return config
  })

  axiosInstance.interceptors.response.use(undefined, errorHandlerRegular)

  apiClient = axiosInstance
  return axiosInstance
}

export const gatewayApiQuery = async <T = unknown>({
  url,
  method,
  data,
  params,
  paramsSerializer,
}: FetchParams): Promise<MaybePromise<QueryReturnValue<T>>> => {
  try {
    const apiClientLocal = gatewayApi()
    const result = await apiClientLocal<T>({ url, method, data, params, paramsSerializer })

    return {
      data: result.data,
    }
  } catch (axiosError) {
    const err = axiosError as AxiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
}
