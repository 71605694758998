import React, { InputHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import InputMasked from 'react-input-mask'
import { fontFamilyMono, thinMedia, colors } from '~/lib/theme'
import { Styleable } from '~/models'

interface Props extends InputHTMLAttributes<HTMLInputElement>, Styleable {
  label?: string
  value: string
  mask: string
  isError?: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function ProgramNumberInput({
  label,
  className,
  value,
  handleChange,
  mask,
  isError = false,
  ...inputProps
}: Props) {
  const maskToUse = mask === 'ANY' ? [/.*/g] : mask

  return (
    <Container className={className} isDisabled={inputProps.disabled ?? false} isError={isError}>
      {label && (
        <Label isDisabled={inputProps.disabled ?? false} isError={isError}>
          {label}
        </Label>
      )}
      <PlaceholderInput disabled value={value} mask={maskToUse} maskChar="_" alwaysShowMask />
      <Input
        {...inputProps}
        value={value}
        mask={maskToUse}
        onChange={handleChange}
        maskChar=""
        alwaysShowMask={false}
        autoFocus
      />
    </Container>
  )
}

const primaryColor = '#8e8f98'
const colorDisabled = '#e5e5e5'

type LabelProps = { isDisabled: boolean; isError: boolean }

const Container = styled.label<LabelProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  color: ${({ isError, theme }) => (isError ? theme.colors.red : primaryColor)};
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  background-color: ${({ isDisabled }) => isDisabled && colorDisabled};
  border-color: ${getBorderColor};

  & input {
    background-color: transparent;
  }
`

const InputStyle = css`
  ${fontFamilyMono};
  letter-spacing: 0.5px;
  font-weight: 500;
  line-height: 1;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.black};
`

const Input = styled(InputMasked)`
  ${InputStyle};
  display: flex;
  align-items: center;
  z-index: 1;
`

const PlaceholderInput = styled(InputMasked)`
  ${InputStyle};
  display: flex;
  align-items: center;
  opacity: 0.2;
  pointer-events: none;
`

const Label = styled.span<LabelProps>`
  position: absolute;
  top: -1px;
  left: 12px;
  transform: translateY(-50%);
  width: fit-content;
  max-width: calc(100% - 26px - 36px);
  font-size: 12px;
  line-height: 1;
  padding: 0 4px;
  background-color: ${getLabelStyle};
  z-index: 2;
  pointer-events: none;

  ${thinMedia} {
    font-size: 10px;
  }
`

function getBorderColor({ isDisabled, isError }: LabelProps) {
  if (isError) return colors.red
  if (isDisabled) return colorDisabled
  return 'currentColor'
}

function getLabelStyle({ isDisabled, isError }: LabelProps) {
  if (isDisabled && isError) return colorDisabled
  if (isDisabled) return 'transparent'
  return '#fff'
}
