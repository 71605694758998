import { MouseEvent, useCallback } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { desktopMedia, mediaHover } from '~/lib/theme'
import { Styleable, WithChildrenOptional } from '~/models'

export interface Props extends WithChildrenOptional, Styleable {
  hideFadeOnMobile?: boolean
  zIndex?: number
  coversHeader?: boolean
  onClick?: () => void
}

export function FadeBackground({
  children,
  onClick,
  zIndex,
  className,
  coversHeader = true,
  hideFadeOnMobile = false,
}: Props) {
  const fadeClickHandler = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (typeof onClick === 'function') onClick()
    },
    [onClick]
  )

  return (
    <Root
      key="backdrop"
      className={className}
      onClick={fadeClickHandler}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      $zIndex={zIndex}
      $hideFadeOnMobile={hideFadeOnMobile}
      $coversHeader={coversHeader}
    >
      {Boolean(children) && children}
    </Root>
  )
}

const backgroundColor = 'rgba(0, 0, 0, 0.65)'

const Root = styled(motion.div)<{ $hideFadeOnMobile?: boolean; $zIndex?: number; $coversHeader: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${({ theme, $coversHeader }) => ($coversHeader ? 0 : theme.header.height)}px;
  z-index: ${({ $zIndex }) => $zIndex ?? 9999};
  pointer-events: ${({ $hideFadeOnMobile }) => $hideFadeOnMobile && 'none'};
  background-color: ${({ $hideFadeOnMobile }) => ($hideFadeOnMobile ? 'transparent' : backgroundColor)};
  backdrop-filter: ${({ $hideFadeOnMobile }) => ($hideFadeOnMobile ? 'none' : 'blur(4px)')};

  ${mediaHover} {
    cursor: pointer;
  }

  ${desktopMedia} {
    backdrop-filter: blur(4px);
    background-color: ${backgroundColor};
    pointer-events: all;
  }
`
