import { FallbackRender } from '@sentry/nextjs'
import { FallbackComponentBase } from './FallbackComponentBase'

export function FallbackComponent({ error, componentStack }: Parameters<FallbackRender>[0]) {
  return (
    <FallbackComponentBase>
      <div>{error.stack?.substring(0, 360) || error.toString()}</div>
      <div>{componentStack?.substring(0, 120)}</div>
    </FallbackComponentBase>
  )
}
