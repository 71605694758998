export function luhnProgramNumberValidator(programNumber: string) {
  const cleanedNumber: string = programNumber.replace(/\D/g, '')

  if (cleanedNumber.length !== 16 || !/^\d+$/.test(cleanedNumber)) return false

  let sum: number = 0
  let isSecondDigit: boolean = false

  for (let i: number = cleanedNumber.length - 1; i >= 0; i--) {
    let digit: number = parseInt(cleanedNumber.charAt(i), 10)

    if (isSecondDigit) {
      digit *= 2
      if (digit > 9) {
        digit -= 9
      }
    }

    sum += digit
    isSecondDigit = !isSecondDigit
  }

  return sum % 10 === 0
}