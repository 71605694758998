import styled from 'styled-components'
import { mediaHover } from '~/lib/theme'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'

interface Props {
  fillBrandColor?: boolean
  onIconClick?: () => void
}

export function IconBack({ onIconClick, fillBrandColor }: Props) {
  const whitelabel = useSelector(whiteLabelSelector)
  const { brand_color } = whitelabel.theme
  const fillColor = fillBrandColor ? brand_color : '#fff'

  return (
    <Container onClick={onIconClick}>
      <svg width={28} height={28} fill={fillColor} viewBox="0 0 28 28">
        <path d="m13 23.414-9.414-9.414 9.414-9.414 1.414 1.414-7 7h16.586v2h-16.586l7 7z" />
      </svg>
    </Container>
  )
}

const Container = styled.button`
  display: flex;
  width: 28px;
  height: 28px;
  transition: opacity 0.2s ease-in-out;

  ${mediaHover} {
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  &:active {
    opacity: 0.7;
  }
`
