import { useEffect } from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { redirectTo } from '~/lib/api/redirectBus'
import { proxy, useSnapshot } from 'valtio'

export const needsToMakeRedirect = proxy<{ trigger: boolean }>({ trigger: false })

export function useRedirectService() {
  const redirectSnap = useSnapshot(redirectTo)
  const triggerSnap = useSnapshot(needsToMakeRedirect)
  const addressTo = redirectSnap.address
  const router = useRouter()
  const path = usePathname()

  const query = useSearchParams()
  const isWindowClose = Boolean(query.get('close_window'))

  useEffect(() => {
    if (addressTo && !isWindowClose) {
      router.push(addressTo)
      redirectTo.address = null
      needsToMakeRedirect.trigger = true
    }
  }, [addressTo, isWindowClose, router, triggerSnap.trigger])

  useEffect(() => {
    if (isWindowClose && path && triggerSnap.trigger) {
      needsToMakeRedirect.trigger = false
      router.push(`${path}?close_window=true`)
    }
  }, [isWindowClose, path, router, triggerSnap.trigger])
}
