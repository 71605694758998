export type ProcessStatus = 'pending' | 'rejected' | 'fulfilled' | 'idle'
export type SecondOptionCombination = 'NEXT_MONTH_FIRST' | 'REPEATABLE_FIRST' | 'UNREPEATABLE_FIRST' | 'NEXT_DAY_ONCE'

type OptionComponents = any

export interface Option {
  optionId: number
  pluggable: boolean
  mnemocode: string
  bankMnemocode: string
  lifetime: string
  startDate: string
  finishDate: string
  components: OptionComponents
  active: boolean
  visible: boolean
  secondOptionCombination: SecondOptionCombination
}

export interface ClientOption {
  mnemocode?: string
  optionId: number
  productOptionId: number
  isDefault: boolean
  name: string
  startDate: string
  finishDate: string
  createdBy: string
  status: OptionStatus
  goals: number[]
  visible?: boolean
}

// eslint-disable-next-line no-shadow
export enum OptionStatus {
  PAST = 'PAST',
  PRESENT = 'PRESENT',
  FUTURE = 'FUTURE',
}

export interface ByProduct {
  option_id: number
  name: string
  components: any
  secondOptionCombination: SecondOptionCombination
  programs: ByProgram[]
}

export interface ByProductWithMnemocode extends ByProduct {
  mnemocode: string
}

export interface ByProgramResponse {
  product_option_id: number
  is_active: boolean
  is_default: boolean
  loyalty_code: string
}

export interface ByProgram extends ByProgramResponse {
  option_name: string
  productClass: string
  optionId: number
}

export type ProductOptionsByProduct = Record<string, ByProduct[]>
export type ProductOptionsByProgram = Record<string, ByProgram[]>

export interface ProductOptions {
  byProduct: ProductOptionsByProduct
  byProgram: ProductOptionsByProgram
}

export interface OptionByProduct extends ByProduct, Pick<Option, 'pluggable' | 'mnemocode' | 'visible'> {}

export interface OptionsState {
  productOptionChangeStatus: ProcessStatus
  isFirstOptionChange: boolean
}
