import { Step } from '~/models'
import { useTypedSelector } from '~/store/redux-store'

export function useGetCurrentStep() {
  const steps = useTypedSelector(({ process }) => process.steps)
  const currentStep = useTypedSelector(({ process }) => process.currentStep)
  const direction = useTypedSelector(({ process }) => process.direction)

  if (!steps || !steps.length) {
    return {
      step: {
        component: undefined,
        title: undefined,
      } as unknown as Step,
      direction,
      stepsAmount: 0,
    }
  }

  const step: Step = steps[currentStep]
  const stepsAmount = steps.length

  return { step, direction, stepsAmount, currentStepNum: currentStep }
}
