import React, { type HTMLAttributeAnchorTarget, type MouseEvent, useCallback } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { mediaHover } from '~/lib/theme'
import type { Styleable, WithChildren } from '~/models'
import { typographyCss, type TypographyVariant } from '@/Typography'
import Link from 'next/link'

type ButtonVariant = 'contained' | 'outlined'
type Size = 'small' | 'normal'
type ActiveStyle = 'standard'

export interface ButtonProps extends WithChildren, Styleable {
  variant?: ButtonVariant
  size?: Size
  isDisabled?: boolean
  isFullWidth?: boolean
  href?: string
  rel?: string
  target?: HTMLAttributeAnchorTarget
  onClick?: () => void
}

export function Button({
  className,
  children,
  isDisabled,
  variant = 'contained',
  size = 'normal',
  isFullWidth,
  href,
  rel,
  target,
  onClick,
}: ButtonProps) {
  const { linkOpenTarget } = useSelector(whiteLabelSelector)
  const activeStyle: ActiveStyle = 'standard'
  const isInternalLink = typeof href === 'string' && href.startsWith('/')

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (!href) e.preventDefault()
      if (onClick) onClick()
    },
    [href, onClick]
  )

  if (isInternalLink) {
    return (
      <Link href={href} onClick={clickHandler}>
        <ButtonView as="div" $activeStyle={activeStyle} $size={size} className={className} $variant={variant}>
          {children}
        </ButtonView>
      </Link>
    )
  }

  if (href) {
    return (
      <ButtonView
        as="a"
        $activeStyle={activeStyle}
        $size={size}
        className={className}
        href={href}
        rel={rel ?? 'noreferrer'}
        target={target ?? linkOpenTarget}
        $variant={variant}
        onClick={clickHandler}
      >
        {children}
      </ButtonView>
    )
  }

  return (
    <ButtonView
      $activeStyle={activeStyle}
      $size={size}
      disabled={isDisabled}
      onClick={clickHandler}
      className={className}
      $variant={variant}
      $isFullWidth={isFullWidth}
    >
      {children}
    </ButtonView>
  )
}

const overlayColorsForStates: Record<ActiveStyle, Record<'regular' | 'hover' | 'active', string>> = {
  standard: {
    regular: 'transparent',
    hover: 'rgba(255, 255, 255, 0.1)',
    active: 'rgba(0, 0, 0, 0.15)',
  },
}

type Appearance = {
  typography: TypographyVariant
  height: number
  padding: number
  borderRadius: number
}

const appearanceBySize: Record<Size, Appearance> = {
  normal: {
    typography: 't1semiBold',
    height: 48,
    padding: 24,
    borderRadius: 16,
  },
  small: {
    typography: 't3semiBold',
    height: 24,
    padding: 8,
    borderRadius: 8,
  },
}

const ButtonView = styled.button<{
  $variant: ButtonVariant
  $isSuccess?: boolean
  $isFullWidth?: boolean
  $activeStyle: ActiveStyle
  $size: Size
}>`
  --button-overlay-color: ${({ $activeStyle }) => overlayColorsForStates[$activeStyle].regular};
  --button-color: ${({ theme }) => theme.brand_color};
  ${({ $size }) => typographyCss[appearanceBySize[$size].typography]};
  height: ${({ $size }) => appearanceBySize[$size].height}px;
  padding: 0 ${({ $size }) => appearanceBySize[$size].padding}px;
  border-radius: ${({ $size }) => appearanceBySize[$size].borderRadius}px;
  width: ${({ $isFullWidth }) => $isFullWidth && '100%'};
  color: ${({ $variant }) => ($variant === 'contained' ? '#fff' : 'var(--button-color)')};
  background-color: ${({ $variant }) => ($variant === 'contained' ? 'var(--button-color)' : '#fff')};
  user-select: none;
  font-family: inherit;
  position: relative;
  flex-shrink: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: filter, background-color, color, border-color;
  border: 2px solid var(--button-color);

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: -2px;
    top: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 16px;
    pointer-events: none;
    mix-blend-mode: overlay;
    transition: background-color 0.2s ease-in-out;
    background-color: var(--button-overlay-color);
  }

  ${mediaHover} {
    cursor: pointer;
    &:hover {
      --button-overlay-color: ${({ $activeStyle }) => overlayColorsForStates[$activeStyle].hover};
    }
  }
  &:active {
    --button-overlay-color: ${({ $activeStyle }) => overlayColorsForStates[$activeStyle].active};
  }

  &:disabled {
    pointer-events: none;
    color: #c5c8cf;
    background-color: ${({ $variant }) => ($variant === 'contained' ? '#f8f9fb' : '#fff')};
    border-color: ${({ $variant }) => ($variant === 'contained' ? '#f8f9fb' : '#c5c8cf')};
  }
`
