import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { useGetCurrentStep } from '@/MultiStepModal/hooks/useGetCurrentStep'
import { useSetStepActions } from '../hooks/useSetStepActions'

interface ModalControlProps {
  onPrevClick?: () => void
  onNextClick?: () => void
  textPrev?: string
  textNext?: string
  disabledPrev?: boolean
  disabledNext?: boolean
  noPrev?: boolean
  noNext?: boolean
}

export function ModalControlsBlock({
  onNextClick,
  onPrevClick,
  disabledNext,
  disabledPrev,
  noPrev,
  noNext,
  textNext,
  textPrev,
}: ModalControlProps) {
  const intl = useIntl()
  const { setPrevStep, setNextStep } = useSetStepActions()
  const { currentStepNum, stepsAmount } = useGetCurrentStep()

  const textNextFinal = textNext ?? (intl.messages[`app.misc.forward`] as unknown as string)
  const textPrevFinal = textPrev ?? (intl.messages[`app.misc.back`] as unknown as string)

  const prevClickHandler = onPrevClick || setPrevStep
  const nextClickHandler = onNextClick || setNextStep

  const isFirstStep = currentStepNum === 0
  const isLastStep = currentStepNum === stepsAmount - 1

  const isPrevDisabled = disabledPrev || isFirstStep
  const isNextDisabled = disabledNext || isLastStep

  return (
    <ModalControlContainer>
      <ModalControlAction disabled={isPrevDisabled} onClick={prevClickHandler} notShow={noPrev || isFirstStep}>
        {textPrevFinal}
      </ModalControlAction>
      <ModalControlAction disabled={isNextDisabled} onClick={nextClickHandler} notShow={noNext || isLastStep}>
        {textNextFinal}
      </ModalControlAction>
    </ModalControlContainer>
  )
}

export const ModalControlContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
`

export const ModalControlAction = styled.button<{ notShow?: boolean }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  flex: 1 1 0;
  color: #e2001a;
  cursor: pointer;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  border: none;
  background-color: transparent;

  ${({ notShow }) =>
    notShow &&
    css`
      pointer-events: none;
      visibility: hidden;
      display: none;
    `}

  &:disabled {
    color: #67616a;
    pointer-events: none;
  }
`
