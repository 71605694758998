import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OptionsState } from '~/models'
import { optionsApi } from '~/store/api/options'

const initialState: OptionsState = {
  productOptionChangeStatus: 'idle',
  isFirstOptionChange: false,
}

const optionsSlice = createSlice({
  name: 'optionsProcess',
  initialState,
  reducers: {
    resetProductOptionChangeStatus(state: OptionsState) {
      state.productOptionChangeStatus = 'idle'
    },
    setFirstOptionChangingStatus(state: OptionsState, { payload }: PayloadAction<boolean>) {
      state.isFirstOptionChange = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(optionsApi.endpoints.setProductOption.matchPending, (state: OptionsState) => {
      state.productOptionChangeStatus = 'pending'
    })
    builder.addMatcher(optionsApi.endpoints.setProductOption.matchFulfilled, (state: OptionsState) => {
      state.productOptionChangeStatus = 'fulfilled'
    })
    builder.addMatcher(optionsApi.endpoints.setProductOption.matchRejected, (state: OptionsState) => {
      state.productOptionChangeStatus = 'rejected'
    })
  },
})

export default optionsSlice.reducer
export const optionsActions = optionsSlice.actions
