import { format, sub, add } from 'date-fns'

type OftenPeriods = 'thisMonth' | 'lastMonth' | 'nextMonth'

export function getOftenPeriod(month: OftenPeriods) {
  const resultMonth = getResultMonth(month)

  return dateToPeriod(resultMonth)
}

export const periodsCanBeOnline = [getOftenPeriod('thisMonth'), getOftenPeriod('lastMonth')]

export function dateToPeriod(period: Date) {
  return format(period, 'yyyy-MM')
}

function getResultMonth(month: OftenPeriods) {
  switch (month) {
    case 'thisMonth':
      return new Date()
    case 'lastMonth':
      return sub(new Date(), { months: 1 })
    case 'nextMonth':
      return add(new Date(), { months: 1 })
    default:
      throw new Error('Wrong period in getOftenPeriod')
  }
}
