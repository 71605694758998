import { memo } from 'react'
import styled from 'styled-components'
import { mobileMedia } from '~/lib/theme'
import { Image } from '@/Image'

interface Props {
  name?: string
  size?: number
  mobileSize?: number
  className?: 'string'
}

export const IconProgramFromStatic = memo(IconProgramFromStaticView)

export function IconProgramFromStaticView({ name, size = 56, mobileSize = size, className }: Props) {
  if (!name) return null

  const extension = getIconExtension(name)
  const iconSrc = `/program-icons/${name}.${extension}`

  return <Container size={size} mobileSize={mobileSize} className={className} src={iconSrc} />
}

const Container = styled(Image)<{ size: number; mobileSize: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => Math.round(size * 0.143)}px;

  ${mobileMedia} {
    width: ${({ mobileSize }) => (mobileSize ? `${mobileSize}px` : '40px')};
    height: ${({ mobileSize }) => (mobileSize ? `${mobileSize}px` : '40px')};
    border-radius: ${({ mobileSize }) => Math.round(mobileSize * 0.143)}px;
  }
`

const pngIcons = [
  'FDM',
  'FHV',
  'FNH',
  'FNL',
  'FPZ',
  'FSF',
  'FSR',
  'FZC',
  'AKB_PACKAGE_KKB',
  'AKB_PACKAGE_KKP',
  'AKB_PACKAGE_KK1',
  'AKB_PACKAGE_KK2',
  'AKB_PACKAGE_KK3',
  'AKB_PACKAGE_KK4',
]

export function getIconExtension(name: string) {
  return pngIcons.includes(name) ? 'png' : 'svg'
}
