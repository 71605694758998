import { convertValueToCents } from '~/lib/utils/convertValueToCents'

/*
  JSON должен содержать имена валют в правильном порядке:
  1) Именительный, единственное число – 'Бонус',
  2) Родительный, единственное число – 'Бонуса',
  3) Родительный, множественное число, ноль – 'Бонусов',
  4) Именительный, множественное число – 'Бонусы',
 */

export function plural(forms: string[], val: number): string {
  if (!Array.isArray(forms) || forms.length < 3) return ''
  if (typeof val !== 'number') return forms[2]

  function getIdx(currentVal: number) {
    if (currentVal % 10 === 1 && currentVal % 100 !== 11) {
      return 0 // many
    }
    if (currentVal % 10 >= 2 && currentVal % 10 <= 4 && (currentVal % 100 < 10 || currentVal % 100 >= 20)) {
      return 1 // few
    }
    return 2 // one
  }

  const valInt = parseInt(String(val), 10)

  return forms[getIdx(Math.abs(valInt))] || forms[2]
}

export function getArrayCurrencyNames(currenciesJson?: string) {
  if (typeof currenciesJson !== 'string') return []

  const re = /'/gi
  const normalizedCurrenciesJson = currenciesJson.replace(re, '"')

  return JSON.parse(normalizedCurrenciesJson)
}

export function getPluralCurrency(currenciesJson: string | undefined, val: number, noTranslation?: boolean) {
  if (typeof currenciesJson !== 'string') return ''
  const currenciesArray = getArrayCurrencyNames(currenciesJson)

  const valInput = Number.isInteger(val) ? val : 0
  const valFinal = noTranslation ? valInput : convertValueToCents(valInput)

  return plural(currenciesArray, valFinal)
}