import { MultiStepModal } from '@/MultiStepModal/MultiStepModal'
import { useSetStepActions } from '@/MultiStepModal/hooks/useSetStepActions'
import { Step } from '~/models'
import { Authorization } from './components/Authorization'
import { Registration } from './components/Registration'

export function AuthorizationFlow() {
  const { setNextStep } = useSetStepActions()

  const getComponent = ({ component }: Step) => {
    switch (component) {
      case 'authorization':
        return <Authorization />
      case 'registration':
        return <Registration onNextClick={setNextStep} />
      default:
        return null
    }
  }

  return <MultiStepModal getComponent={getComponent} />
}
