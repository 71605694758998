import styled from 'styled-components'
import { desktopMedia } from '~/lib/theme'
import { BtnActionsStyle } from '@/BtnActionsStyle'
import { Styleable } from '~/models'

export interface CloseBtnProps extends Styleable {
  sizeContainer?: number
  sizeIcon?: number
  color?: string
  strokeWidth?: number
  onClick?: () => void
  hideOnMobile?: boolean
  hideOnDesktop?: boolean
}

export function CloseButton({
  onClick,
  className,
  hideOnMobile = false,
  hideOnDesktop = false,
  strokeWidth = 2,
  sizeIcon = 24,
  sizeContainer = 24,
  color = '#8e8f98',
}: CloseBtnProps) {
  return (
    <Root
      onClick={onClick}
      className={className}
      $hideOnMobile={hideOnMobile}
      $hideOnDesktop={hideOnDesktop}
      $size={sizeContainer}
    >
      <svg
        fill="none"
        width={sizeIcon}
        height={sizeIcon}
        stroke={color}
        viewBox="0 0 24 24"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      >
        <path d="M7 17L17 7M7 7l10 10" />
      </svg>
    </Root>
  )
}

const Root = styled.button<{ $hideOnMobile: boolean; $hideOnDesktop: boolean; $size: number }>`
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'flex')};
  ${BtnActionsStyle};

  ${desktopMedia} {
    display: ${({ $hideOnDesktop }) => ($hideOnDesktop ? 'none' : 'flex')};
  }
`
