import { createApi } from '@reduxjs/toolkit/query/react'
import { Product } from '~/models'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { createSelector } from 'reselect'

const axiosRTQuery = createAxiosRTQuery()

export const productApi = createApi({
  reducerPath: 'products',
  baseQuery: axiosRTQuery({
    baseUrl: 'brothers/api/partner',
  }),
  endpoints: (build) => ({
    getProducts: build.query<Product[], void>({
      query: () => ({ url: '/products', method: 'get' }),
      transformResponse: (response: { data: Product[] }) => response.data,
    }),
  }),
})

export const { useGetProductsQuery } = productApi

export const productsQuerySelector = createSelector(
  productApi.endpoints.getProducts.select(),
  (result) => result?.data ?? []
)
