import { useIntl } from 'react-intl'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetProgramStatusText } from '~/hooks/useGetProgramStatusText'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { ModalControlsBlock } from '@/MultiStepModal/components/ModalControlsBlock'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useRegisterEntryMutation } from '~/store/api/entries'
import { useTypedSelector } from '~/store/redux-store'
import { ScrollbarWrapper } from '@/ScrollbarWrapper'
import { Break } from '@/Break'
import { Container, Title } from './common/stylesCommon'
import { Meta } from './common/Meta'
import { ButtonWithSuccessState } from './common/ButtonWithSuccessState'

type ProgramsAcceptConditions = '366' | 'GRZ'
type Conditions = Record<ProgramsAcceptConditions, string>

const disclaimers: Conditions = {
  366: '366_TERM_V1',
  GRZ: 'GRZ_TERM_V1',
}
const rules: Conditions = {
  366: 'https://366.ru/pravila_programmy/',
  GRZ: 'https://gorzdrav.org/club_6/',
}

interface Props {
  onNextClick: () => void
}

export function Registration({ onNextClick }: Props) {
  const intl = useIntl()
  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const [registerEntry, { isSuccess: isRegistrationOk }] = useRegisterEntryMutation()
  const { linkOpenTarget } = useSelector(whiteLabelSelector)

  const program = useGetPrograms(selectedProgram!)
  const { entryClass, entryName, hasEntry, details } = program
  const { icon, name } = details

  const doRegistration = () => {
    const disclaimer = entryClass in disclaimers ? disclaimers[entryClass as ProgramsAcceptConditions] : ''

    registerEntry({
      entry_class: entryClass,
      accept_disclaimers: [`${disclaimer}`],
    })
  }

  const statusText = useGetProgramStatusText(selectedProgram!, true)

  useEffect(() => {
    if (hasEntry && !isRegistrationOk) onNextClick()
  }, [hasEntry, isRegistrationOk, onNextClick])

  const rule = entryClass in rules ? rules[entryClass as ProgramsAcceptConditions] : '/'

  return (
    <AnimatedContainer animationKey="Registration">
      <Meta
        icon={icon}
        title={name}
        rows={[
          { param: intl.messages[`app.program.status_prefix`] as unknown as string, value: statusText ?? '' },
          {
            param: intl.messages[`app.program.card_name_prefix`] as unknown as string,
            value: entryName ?? (intl.messages[`app.program.no_entry_name`] as unknown as string),
          },
        ]}
      />
      <Container gapRow={24}>
        <Title>{intl.formatMessage({ id: `app.program.registration.will_no_bonus_card` }, { name })}</Title>
        <ScrollbarWrapper>
          <Title
            dangerouslySetInnerHTML={{
              __html: intl
                .formatMessage({ id: `app.program.registration.disclaimer` })
                .replace(':rule:', rule)
                .replace(':target:', linkOpenTarget),
            }}
          />
        </ScrollbarWrapper>
      </Container>
      <Break indent={24} />
      <ButtonWithSuccessState variant="outlined" onClick={doRegistration} isSuccess={isRegistrationOk}>
        {isRegistrationOk
          ? (intl.messages[`app.program.registration.already_registered`] as unknown as string)
          : (intl.messages[`app.program.registration.make_card`] as unknown as string)}
      </ButtonWithSuccessState>
      <ModalControlsBlock textNext={intl.messages[`app.misc.forward`] as unknown as string} disabledNext={!hasEntry} />
    </AnimatedContainer>
  )
}
