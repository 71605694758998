import styled, { css } from 'styled-components'
import { Image } from '@/Image'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'

interface Props {
  pictFrom?: string
  pictTo?: string
  textFrom?: string
  textTo?: string
  shouldNotShowThis: boolean
  shouldNotShowBoth: boolean
}

export function ChangedItemsElement({ pictFrom, pictTo, textFrom, textTo, shouldNotShowBoth, shouldNotShowThis }: Props) {
  const { theme } = useSelector(whiteLabelSelector)

  return (
    <Container>
      {!shouldNotShowBoth && (
        <ItemFirst>
          {!shouldNotShowThis && (
            <>
              <Fade />
              <ItemFirstIcon>
                <Img src={pictFrom} alt="" fit="cover" />
              </ItemFirstIcon>
              <TextFirst>{textFrom}</TextFirst>
              <ArrowContainer>
                <Arrow arrowColor={theme.brand_color} />
              </ArrowContainer>
            </>
          )}
        </ItemFirst>
      )}
      <ItemLast>
        <ItemLastIcon color={theme.brand_color}>
          <Img src={pictTo} alt="" fit="cover" />
        </ItemLastIcon>
        <TextLast>{textTo}</TextLast>
      </ItemLast>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
`

const NoOverflow = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const TextFirst = styled.span`
  width: 98px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
  margin-left: 4px;
  ${NoOverflow};
`

const TextLast = styled.span`
  width: calc(100% - 4px);
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 20px;
  margin-left: 4px;
  ${NoOverflow};
`

const ItemFirst = styled.div`
  width: 132px;
  display: flex;
  flex-direction: column;
  position: relative;
`

const ItemLast = styled.div`
  width: 128px;
  display: flex;
  flex-direction: column;
`

const ItemFirstIcon = styled.div`
  width: 70px;
  height: 48px;
  border-radius: 4px;
  border: 2px solid #e0e0e0;
  padding: 2px;

  > div {
    border-radius: 2px;
  }
`

const ItemLastIcon = styled.div<{ color: string }>`
  height: 85px;
  border-radius: 8px;
  border: 2px solid ${({ color }) => color};
  padding: 2px;

  > div {
    border-radius: 6px;
  }
`

const Img = styled(Image)`
  width: 100%;
  height: 100%;
`

const ArrowContainer = styled.div`
  width: 38px;
  height: 14px;
  position: absolute;
  top: 17px;
  right: 14px;
  display: flex;
`

const Fade = styled.div`
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: 40px;
  right: 20px;
  background-image: linear-gradient(93deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.75) 40%);
`

interface ArrowProps {
  arrowColor: string
}

const Arrow = ({ arrowColor }: ArrowProps) => (
  <svg
    fill="none"
    height="14"
    width="38"
    viewBox="0 0 38 14"
    stroke={arrowColor}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.5"
  >
    <path d="m36.3333 6.66667h-35.3333m35.3333 0-5.6666-5.66667m5.6666 5.66667-5.6666 5.66663" />
  </svg>
)
