import styled from 'styled-components'
import { mobileMedia, thinMedia } from '~/lib/theme'

export const Title = styled.span<{ mobileHidden?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  color: #03030b;
  min-height: 30px;

  ${mobileMedia} {
    ${({ mobileHidden }) => (mobileHidden ? 'display: none' : '')}
  }

  ${thinMedia} {
    font-size: 18px;
  }
`
