import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { modalState } from '@/Modal'

export function useCloseModalOnPageLeft() {
  const router = useRouter()

  const handleRouteChange = useCallback(() => {
    modalState.activeModalId = undefined
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [handleRouteChange, router.events])
}
