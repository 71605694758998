import styled, { css } from 'styled-components'
import { ComponentProps, ReactNode } from 'react'

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 't1bold'
  | 't1semiBold'
  | 't1medium'
  | 't1regular'
  | 't2bold'
  | 't2semiBold'
  | 't2medium'
  | 't2regular'
  | 't3semiBold'
  | 't3medium'
  | 't3regular'

type Variants = { [key in TypographyVariant]: ReturnType<typeof styled.div> }

interface BaseProps {
  className?: string
  variant: TypographyVariant
}

interface PropsWithChildren {
  children: ReactNode
  dangerouslySetInnerHTML?: never
}

interface PropsWithHtml {
  children?: never
  dangerouslySetInnerHTML: ComponentProps<Variants[TypographyVariant]>['dangerouslySetInnerHTML']
}

type Props = BaseProps & (PropsWithChildren | PropsWithHtml)

export function Typography({ children, variant, className, dangerouslySetInnerHTML }: Props) {
  const Variant = variants[variant]

  return (
    <Variant className={className} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
      {children}
    </Variant>
  )
}

export const typographyCss: { [key in TypographyVariant]: ReturnType<typeof css> } = {
  h1: css`
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.5px;
    line-height: 38px;
  `,
  h2: css`
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  `,
  h3: css`
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
  `,
  h4: css`
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  `,
  t1bold: css`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  `,
  t1semiBold: css`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  `,
  t1medium: css`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  t1regular: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  t2bold: css`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  `,
  t2semiBold: css`
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  `,
  t2medium: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  `,
  t2regular: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  `,
  t3semiBold: css`
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  `,
  t3medium: css`
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  `,
  t3regular: css`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
  `,
}

const variants = Object.entries(typographyCss).reduce((acc, [key, variantCss]) => {
  acc[key as TypographyVariant] = styled.div`
    ${variantCss};
  `

  return acc
}, {} as Variants)
