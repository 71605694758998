import styled, { css } from 'styled-components'

export const TextOverflow = css<{ linesClamp?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ linesClamp }) =>
    linesClamp
      ? css`
          display: -webkit-box;
          -webkit-line-clamp: ${linesClamp};
          -webkit-box-orient: vertical;
        `
      : css`
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
`

export const TextOverflowWrapper = styled.span<{ linesClamp?: number }>`
  ${TextOverflow};
`
