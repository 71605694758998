import { AxiosError, AxiosRequestConfig } from 'axios'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react'
import { ApiClientType, createAxiosInstance } from './createAxiosInstance'

export const createAxiosRTQuery = (apiType?: ApiClientType, requestConfig?: Partial<AxiosRequestConfig>) => {
  const apiClient = createAxiosInstance({ apiType, requestConfig })

  return (
      { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
      {
        url: string
        method: AxiosRequestConfig['method']
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
        paramsSerializer?: AxiosRequestConfig['paramsSerializer']
      },
      unknown,
      unknown
    > =>
    async ({ url, method, data, params, paramsSerializer }) => {
      try {
        const result = await apiClient({ url: baseUrl + url, method, data, params, paramsSerializer })
        return { data: result.data }
      } catch (axiosError) {
        const err = axiosError as AxiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }
}
