export const banksPrograms = [
  '99990017',
  '99990029',
  '99990051',
  '99990052',
  '99990055',
  '99990040',
  '99990063',
  '99990061',
  '99990078',
]
export const cashbackPrograms = ['99990015', '99990038']
export const specialCashbackPrograms = ['99990078']
export const ottPrograms = ['99990017', '99990052']
export const convertiblePrograms = [
  '99990028',
  '99990029',
  '99990039',
  '99990040',
  '99990041',
  '99990042',
  '99990043',
  '99990044',
  '99990047',
  '99990048',
  '99990049',
  '99990051',
  '99990063',
  '99990065',
  '99990061',
  '99990075',
]
export const spendButtonPrograms = ['99990029', '99990075', '99990063']
export const mgcPrograms = ['99990063']
export const charityPrograms = ['99990037']
export const banksProgramsAutoConverted = ['99990055']

export function checkProgramType(mnemocode: string) {
  const programIsBanks = banksPrograms.includes(mnemocode)
  const programIsCashback = cashbackPrograms.includes(mnemocode)
  const programIsSpecialCashback = specialCashbackPrograms.includes(mnemocode)
  const programIsOtt = ottPrograms.includes(mnemocode)
  const programIsConvertible = convertiblePrograms.includes(mnemocode)
  const programIsCharity = charityPrograms.includes(mnemocode)
  const programHasSpendButton = spendButtonPrograms.includes(mnemocode)
  const programHasMgcButton = mgcPrograms.includes(mnemocode)
  const programIsBanksAutoConverted = banksProgramsAutoConverted.includes(mnemocode)

  return {
    programIsBanks,
    programIsCashback,
    programIsOtt,
    programIsConvertible,
    programIsCharity,
    programHasSpendButton,
    programHasMgcButton,
    programIsSpecialCashback,
    programIsBanksAutoConverted
  }
}
