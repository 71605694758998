import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import LoginLayout from '~/components/login/LoginLayout'
import { LoginStatusMessage } from '@/LoginStatusMessage'
import { useIdleTimer } from 'react-idle-timer'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useTypedSelector } from '~/store/redux-store'
import { Authenticated } from './Authenticated'
import { useCloseModalOnPageLeft } from './useCloseModalOnPageLeft'

export type AuthGuardProps = Pick<AppProps, 'Component' | 'pageProps'>

const unprotectedRoutes = [
  '/login',
  '/login/oauth',
  '/login/authorization',
  '/login/auth-error',
]

export function AuthGuard({ Component, pageProps }: AuthGuardProps) {
  const router = useRouter()
  const { session_duration } = useSelector(whiteLabelSelector)
  useCloseModalOnPageLeft()

  const endSessionHandler = useCallback(() => {
    if (session_duration) {
      router.push('/login/auth-error?reason=expired')
    }
  }, [router, session_duration])

  useIdleTimer({
    onIdle: endSessionHandler,
    startOnMount: true,
    eventsThrottle: 5,
    disabled: !session_duration,
    // timeout can't be less than promptBeforeIdle (0 by default)
    timeout: (session_duration ?? 1) * 1000,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  })

  const isAuthenticated = useTypedSelector(({ authentication }) => authentication.isAuthenticated)
  const routeIsProtected = !unprotectedRoutes.includes(router.route)

  if (routeIsProtected && !isAuthenticated) router.push('/login', '/login', { shallow: true })

  if (routeIsProtected) {
    return (
      <Authenticated fallback={<LoginLayout content={<LoginStatusMessage status="dataLoading" />} />}>
        <Component {...pageProps} />
      </Authenticated>
    )
  }

  return <Component {...pageProps} />
}
