import { ReactNode, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { modalState } from '@/Modal'

interface Props {
  children: ReactNode
}

export function BodyScrollLockWrapper({ children }: Props) {
  const { activeModalId } = useSnapshot(modalState)

  useEffect(() => {
    if (!document) return

    if (activeModalId) document.body.style.overflow = 'hidden'
    if (!activeModalId && document.body.style.overflow === 'hidden') {
      document.body.style.removeProperty('overflow')
      if (!document.body.style[0]) document.body.removeAttribute('style')
    }
  }, [activeModalId])

  return children
}
