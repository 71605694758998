import { createApi } from '@reduxjs/toolkit/query/react'
import { Program } from '~/models'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { createSelector } from 'reselect'

const axiosRTQuery = createAxiosRTQuery()

export const programApi = createApi({
  reducerPath: 'programs',
  baseQuery: axiosRTQuery({
    baseUrl: 'brothers/api/partner',
  }),
  endpoints: (build) => ({
    getPrograms: build.query<Program[], void>({
      query: () => ({ url: '/programs', method: 'get' }),
      transformResponse: (response: { data: Program[] }) => response.data,
    }),
  }),
})

export const { useGetProgramsQuery } = programApi

export const programsQuerySelector = createSelector(
  programApi.endpoints.getPrograms.select(),
  (result) => result?.data ?? []
)
