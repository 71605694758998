/* eslint-disable */
// TODO Посмотреть, почему еслинт ругается и по возможности исправить
/*
 Aeroflot Bonus validation algorithm;
 Aeroflot has two generation of cards
 - Old generation can have minimum 4 nad maximum 8 digits with max value 20198716 (included)
 Mod10 algorithm for old generation:
 1) pad with zeros if less than 10 digits
 2) multiply each digit by its placement except the check digit (last one)
 3) add each of these together
 4) mod10 digit is the check digit

 */

const OLD_NUMBER_MAX_LENGTH = 8
const OLD_NUMBER_MAX_VALUE = 20198716
const NEW_NUMBER_MIN_VALUE = 50101041

export function validatorAFM(programNumber: string) {
  if (Number(programNumber) <= OLD_NUMBER_MAX_VALUE) {
    return checkMod10(programNumber)
  }
  if (Number(programNumber) >= NEW_NUMBER_MIN_VALUE) {
    return checkMod7(programNumber)
  }
  return false
}

function checkMod7(programNumber: string) {
  const programNumberAsArray = programNumber.toString().split('')
  const checkDigit = +programNumberAsArray.pop()!
  const programNumberWithoutCheckDigit = +programNumberAsArray.join('')
  const mod7 = programNumberWithoutCheckDigit - Math.trunc(programNumberWithoutCheckDigit / 7) * 7
  return checkDigit === mod7
}

function checkMod10(programNumber: string) {
  const neededZeroes = 10 - programNumber.length
  for (let i = 0; i < neededZeroes; i++) {
    programNumber = '0' + programNumber
  }
  const programNumberAsArray = programNumber.toString().split('')
  const checkDigit = programNumberAsArray.pop()
  const checkMod10: number[] = []
  programNumberAsArray.forEach((value, index) => {
    const place = ++index
    if (place < 10) {
      checkMod10.push(+value * index)
    }
  })
  return Math.trunc(checkMod10.reduce((a, b) => a + b, 0) % 10) === Number(checkDigit)
}
