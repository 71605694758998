import React, { memo, useCallback, useMemo } from 'react'
import styled, { DefaultTheme } from 'styled-components'
import Div100vh from 'react-div-100vh'
import { colors, mobileMedia, smallDesktopMedia } from '~/lib/theme'
import { PlainHeader } from '@/PlainHeader/PlainHeader'
import { Grid } from '@/Grid'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useIntl } from 'react-intl'
import { useMediaQuery } from '~/hooks/useMediaQuery'

export interface LoginLayoutProps {
  content: React.ReactNode
}

const LoginLayout = memo(LoginLayoutView)

function LoginLayoutView({ content }: LoginLayoutProps) {
  const intl = useIntl()
  const whitelabel = useSelector(whiteLabelSelector)
  const { company, link_url, logo_layout } = whitelabel
  const theme: DefaultTheme = useMemo(() => ({ ...whitelabel.theme, colors }), [whitelabel.theme])

  const logoClickHandler = useCallback(() => {
    window.location.href = link_url
  }, [link_url])

  const isMobile = useMediaQuery()
  const hasPlainHeader = theme?.header?.type === 'plain' && isMobile

  return (
    <Div100vh>
      <PageContainer>
        {hasPlainHeader && (
          <PlainHeader title={intl.messages[`app.misc.back`] as unknown as string} />
        )}
        <ContentWrapper isRow hasPlainHeader={hasPlainHeader}>
          <SideContent>
            <LogoHolder onClick={logoClickHandler}>
              <img src={logo_layout} alt={`${company} logo`} />
            </LogoHolder>
          </SideContent>
          <Content widthAuto>{content}</Content>
        </ContentWrapper>
      </PageContainer>
    </Div100vh>
  )
}

export default LoginLayout

const PageContainer = styled(Grid)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => (theme?.layout?.background_color ? theme.layout.background_color : '#fff')};
`

const ContentWrapper = styled(Grid)<{ hasPlainHeader: boolean }>`
  flex-grow: 1;
  margin-top: ${({ hasPlainHeader, theme }) => hasPlainHeader && `${theme.header.height}px)`};
`

const Content = styled(Grid)`
  justify-content: center;
  align-content: center;
  flex-grow: 1;

  ${smallDesktopMedia} {
    width: 300px;
  }

  ${mobileMedia} {
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`

const SideContent = styled.aside`
  width: 440px;
  flex-shrink: 0;
  padding: 48px;
  background-size: cover;
  background: ${({ theme }) => theme.login_banner};

  ${mobileMedia} {
    display: none;
  }
`

const LogoHolder = styled.div``
