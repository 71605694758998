import {
  RefObject,
  useCallback,
  useRef,
  useState,
  useEffect,
  Ref,
  isValidElement,
  cloneElement,
  Key,
  ReactNode,
} from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { use100vh } from 'react-div-100vh'
import { AngleArrow } from '@/AngleArrow'
import { useShowPopup } from '~/hooks/useShowPopup'
import { DropdownType, DropdownPosition } from './types'
import { Icon } from './components/Icon'
import { Field } from './components/Field'
import { Text } from './components/Text'

const variantsArrow = {
  closed: { transform: 'rotate(0deg)' },
  open: { transform: 'rotate(180deg)' },
}

const transition = { ease: 'easeInOut', duration: 0.2 }

const fieldSizes: Record<DropdownType, number> = {
  smallPictured: 48,
  bigPictured: 64,
  big: 48,
  small: 48,
  simple: 48,
}

export type DropdownOption<T = string> = {
  id: T
  text: string
  icon?: string
}

interface DropdownProps<T> {
  placeholder?: string
  label?: string
  type?: DropdownType
  className?: string
  isDisabled?: boolean
  marginBottomRef?: RefObject<HTMLDivElement>
  marginTopRef?: RefObject<HTMLDivElement>
  customHeader?: ReactNode
  isNoSign?: boolean
  options: DropdownOption<T>[]
  selectedId?: T
  onSelect?: (id: T) => void
}

interface DropdownHeaderProps {
  popupIsVisible?: boolean
  onClick?: () => void
  elementRef?: Ref<HTMLDivElement>
  ref?: Ref<HTMLDivElement>
  type?: DropdownType
  isDisabled?: boolean
}

export interface DropdownCustomHeaderProps
  extends Pick<DropdownHeaderProps, 'popupIsVisible' | 'onClick' | 'elementRef'> {}

export function Dropdown<T>({
  type = 'small',
  options,
  onSelect,
  label,
  className,
  placeholder,
  marginBottomRef,
  marginTopRef,
  customHeader,
  isNoSign,
  isDisabled = false,
  selectedId = options[0]?.id,
}: DropdownProps<T>) {
  const intl = useIntl()
  const refPopup = useRef<HTMLDivElement>(null)
  const refTrigger = useRef<HTMLDivElement>(null)
  const { popupIsVisible, hidePopup, showPopup } = useShowPopup({ refPopup })

  const placeholderFallback = intl.messages[`app.common_parts.read_later`] as unknown as string
  const placeholderFinal = placeholder ?? placeholderFallback

  const { selectedIcon, selectedText } = getSelectedItemParts({ options, selectedId, placeholder: placeholderFinal })
  const dropdownClickHandler = isDisabled ? undefined : showPopup

  const viewportHeight = use100vh()

  const itemsCount = options?.length ?? 0

  const maxItemsToShow = 8

  const fieldSize = fieldSizes[type]
  const dropdownSizeDefaultMax = fieldSize * maxItemsToShow

  const [dropdownSize, setDropdownSize] = useState(dropdownSizeDefaultMax)
  const [dropdownPosition, setDropdownPosition] = useState<DropdownPosition>('bottom')

  const getDropdownPlacementParams = useCallback(
    (
      itemsCountDefault: number,
      diffBottom: number,
      diffTop: number
    ): {
      size: number
      position: DropdownPosition
    } => {
      const itemsCountVisibleBottom = Math.floor(diffBottom / fieldSize)
      const itemsCountVisibleTop = Math.floor(diffTop / fieldSize)

      if (itemsCountVisibleBottom < 2 && itemsCountVisibleTop < 2 && itemsCountDefault >= 2) {
        return { size: 0, position: 'never' }
      }

      if (itemsCountVisibleBottom < 2 && itemsCountDefault >= 2) {
        const position: DropdownPosition = 'top'
        const currentItemsCount = Math.min(itemsCountDefault, itemsCountVisibleTop)

        const dropdownSizeCalculated = currentItemsCount * fieldSize

        const size = Math.min(dropdownSizeDefaultMax, dropdownSizeCalculated)

        return { size, position }
      }

      const currentItemsCount = Math.min(itemsCountDefault, itemsCountVisibleBottom)

      const dropdownSizeCalculated = currentItemsCount * fieldSize

      const size = Math.min(dropdownSizeDefaultMax, dropdownSizeCalculated)
      const position: DropdownPosition = 'bottom'

      return { size, position }
    },
    [dropdownSizeDefaultMax, fieldSize]
  )

  const getDiffTop = useCallback((popupTop: number, containerTop?: number) => {
    if (!containerTop) return popupTop

    const top = Math.max(0, containerTop)

    return popupTop - top
  }, [])

  const getDiffBottom = useCallback((currentHeight: number, popupBottom: number, containerBottom?: number) => {
    if (!containerBottom) return currentHeight - popupBottom

    const bottom = Math.min(currentHeight, containerBottom)

    return bottom - popupBottom
  }, [])

  useEffect(() => {
    const popupContainer = refTrigger?.current
    const popupContainerCoords = popupContainer?.getBoundingClientRect()
    const popupBottomCoord = popupContainerCoords?.bottom
    const popupTopCoord = popupContainerCoords?.top
    const hasPopupTopCoord = typeof popupTopCoord !== 'undefined'
    const hasPopupBottomCoord = typeof popupBottomCoord !== 'undefined'

    const marginBottom = marginBottomRef?.current
    const marginBottomCoords = marginBottom?.getBoundingClientRect()
    const marginBottomCoord = marginBottomCoords?.bottom

    const marginTop = marginTopRef?.current
    const marginTopCoords = marginTop?.getBoundingClientRect()
    const marginTopCoord = marginTopCoords?.top

    if (!viewportHeight || !hasPopupBottomCoord || !hasPopupTopCoord) return

    const diffBottom = getDiffBottom(viewportHeight, popupBottomCoord, marginBottomCoord)
    const diffTop = getDiffTop(popupTopCoord, marginTopCoord)

    if (diffBottom < 0 && diffTop < 0) {
      hidePopup()
      return
    }

    const { size, position } = getDropdownPlacementParams(itemsCount, diffBottom, diffTop)
    if (position === 'never') return

    setDropdownPosition(position)
    setDropdownSize(size)
  }, [
    getDiffBottom,
    getDiffTop,
    getDropdownPlacementParams,
    viewportHeight,
    hidePopup,
    itemsCount,
    marginBottomRef,
    marginTopRef,
    popupIsVisible,
  ])

  const CustomHeader = addPropsToChildren(customHeader, {
    popupIsVisible,
    onClick: showPopup,
    elementRef: refTrigger,
  })

  return (
    <Outer className={className}>
      {customHeader ? (
        CustomHeader
      ) : (
        <Header
          $type={type}
          $isVisible={popupIsVisible}
          $isDisabled={isDisabled}
          onClick={dropdownClickHandler}
          ref={refTrigger}
        >
          {label && (
            <Label $isDisabled={isDisabled} $type={type}>
              {label}
            </Label>
          )}
          {(type === 'bigPictured' || type === 'smallPictured') && selectedIcon && (
            <Icon
              $type={type}
              $isDisabled={isDisabled}
              src={selectedIcon}
              alt={`${selectedText} image`}
              fit="contain"
            />
          )}
          <Text $type={type} $isDisabled={isDisabled}>
            {selectedText}
          </Text>
          <Indicator variants={variantsArrow} animate={popupIsVisible ? 'open' : 'closed'} transition={transition}>
            <AngleArrow direction="down" strokeWidth={1.6} color="#67616a" />
          </Indicator>
        </Header>
      )}
      <AnimatePresence>
        {popupIsVisible && (
          <PopupContainer
            ref={refPopup}
            key="profile"
            $type={type}
            $position={dropdownPosition}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={transition}
          >
            <DropdownFields $height={dropdownSize}>
              {options.map(({ id, text, icon }) => (
                <Field
                  key={id as unknown as Key}
                  id={id}
                  type={type}
                  icon={icon}
                  text={text}
                  height={fieldSize}
                  onSelect={onSelect}
                  hidePopup={hidePopup}
                  selectedId={selectedId}
                  isNoSign={isNoSign}
                />
              ))}
            </DropdownFields>
          </PopupContainer>
        )}
      </AnimatePresence>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  user-select: none;
`

const Header = styled.div<{ $type: DropdownType; $isVisible: boolean; $isDisabled: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #9496a6;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  background-color: #fff;

  ${({ $isVisible, theme }) =>
    $isVisible &&
    css`
      border-color: ${theme.brand_color};
      cursor: default;
      pointer-events: none;
    `}

  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: #ccc;
      cursor: default;
      background-color: #eee;
      border-color: #ddd;

      & svg {
        stroke: #ccc;
      }
    `}

  ${({ $type }) => {
    switch ($type) {
      case 'small':
      case 'smallPictured':
        return css`
          height: 48px;
          padding: 0 10px 0 16px;
          border-radius: 16px;
        `
      case 'big':
        return css`
          height: 60px;
          padding: 0 10px 0 30px;
          border-radius: 16px;
        `
      case 'bigPictured':
        return css`
          height: 76px;
          padding: 0 10px 0 20px;
          border-radius: 16px;
        `
      default:
        return null
    }
  }};
`

const Indicator = styled(motion.div)`
  margin-left: 16px;
`

const PopupContainer = styled(motion.div)<{ $type: DropdownType; $position: DropdownPosition }>`
  position: absolute;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.17);
  transform-origin: 0 0;
  overflow: hidden;
  width: 100%;
  border-radius: 16px;

  ${({ $position }) =>
    $position === 'bottom'
      ? css`
          top: calc(100% + 4px);
        `
      : css`
          bottom: calc(100% + 4px);
        `}
`

const DropdownFields = styled.div<{ $height: number }>`
  width: 100%;
  display: flex;
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;
  background-color: #fff;
  max-height: ${({ $height }) => $height}px;
`

const Label = styled.span<{ $type: DropdownType; $isDisabled: boolean }>`
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.0015em;
  color: ${({ $isDisabled }) => ($isDisabled ? '#ccc' : '#67616a')};
  padding: 0 4px;
  position: absolute;
  transform: translateY(-50%);
  background-color: ${({ $isDisabled }) => ($isDisabled ? '#eee' : '#fff')};
  top: -1px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;

  ${({ $type }) => {
    switch ($type) {
      case 'big':
      case 'bigPictured':
        return css`
          left: 30px;
          max-width: calc(100% - 30px - 10px - 20px);
        `
      case 'small':
      case 'smallPictured':
        return css`
          left: 16px;
          max-width: calc(100% - 16px - 4px - 10px);
        `
      default:
        return null
    }
  }};
`

interface GetSelectedItemPartsProps<T> {
  options: DropdownOption<T>[]
  selectedId?: T
  placeholder?: string
}

function getSelectedItemParts<T>({ options, selectedId = options[0]?.id, placeholder }: GetSelectedItemPartsProps<T>) {
  const selectedOption = options?.find(({ id }) => id === selectedId)

  const selectedText = selectedOption?.text ?? placeholder
  const selectedIcon = selectedOption?.icon ?? ''

  return { selectedText, selectedIcon }
}

function addPropsToReactElement(element: ReactNode, props: DropdownCustomHeaderProps): ReactNode {
  if (isValidElement(element)) return cloneElement(element, props)

  return element
}

function addPropsToChildren(
  children: ReactNode | ReactNode[],
  props: DropdownCustomHeaderProps
): ReactNode | ReactNode[] {
  if (!children) return null

  if (!Array.isArray(children)) return addPropsToReactElement(children, props)

  return children.map((childElement) => addPropsToReactElement(childElement, props))
}
