import { EventParameters } from './types/events'

type YM = (tagID: number, ...parameters: EventParameters) => void

export const ym = (tagID: number | null, ...parameters: EventParameters) => {
  // @ts-ignore
  const ymInner = window.ym as YM | undefined

  if (!ymInner || !tagID) return

  ymInner(tagID, ...parameters)
}
export function startAnalytics(id: number | undefined) {
  if (!id) return

  ym(id, 'init', {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  })
}
