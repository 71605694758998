import { createApi } from '@reduxjs/toolkit/query/react'
import {
  EntryDataState,
  Entry,
  EntryByIdMap,
  AuthorizeEntryQueryParams,
  RegisterEntryQueryParams,
  ReplaceNumberQueryParams,
  PlatformStatus,
  PaymentCardResponse,
  PaymentCardType,
  EntryGroup,
} from '~/models'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { createSelector } from 'reselect'
import { gatewayApiQuery, getGatewayApiUrlFromQueryApi, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { getFetchConfigParamsData } from './utils/getFetchConfigParamsData'

interface EntryWithMetadata extends Entry {
  metadata?: {
    authResult: PlatformStatus
  }
}

const axiosRTQuery = createAxiosRTQuery()

export const entriesApi = createApi({
  reducerPath: 'entries',
  baseQuery: axiosRTQuery({
    baseUrl: '00000001/v2',
  }),
  tagTypes: ['Entries'],
  endpoints: (build) => ({
    getEntries: build.query<EntryDataState, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, profileId, company } = getFetchConfigParamsData(queryApi)

        const resEntry = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/aol/profile/${profile_mnemocode}/entry`,
              method: 'GET',
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/entries`,
              method: 'GET',
            })

        const resPaymentCards = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/microservice/metadata/payment_cards`,
              method: 'GET',
              params: {
                profile_code: profile_mnemocode,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/payment-cards`,
              method: 'GET',
            })

        // todo: check with real data
        const resEntryGroups = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/microservice/metadata/entry-groups`,
              method: 'GET',
              params: {
                profileId,
                profile_code: profile_mnemocode,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/entry-groups`,
              method: 'GET',
            })

        const resEntryError = resEntry.error as { status: number; data: unknown }
        const resPaymentCardsError = resPaymentCards.error as { status: number; data: unknown }
        const resEntryGroupsError = resEntryGroups.error as { status: number; data: unknown }
        const resError = resEntryError || resPaymentCardsError || resEntryGroupsError
        if (resError) return { error: 'Something in API went wrong' }

        const resEntryData = resEntry.data as unknown as { status: string; data: Entry[] }
        const { data } = resEntryData
        const paymentCards = (resPaymentCards.data ?? []) as unknown as PaymentCardResponse[]
        const entryGroups = (resEntryGroups.data ?? []) as unknown as EntryGroup[]

        const {
          entries: byMnemocode,
          order: activeEntriesOrder,
          mnemocodesToGroupKeys: mnemocodesToGroupKeysRegular,
        } = makeEntriesSlice({
          data,
          paymentCards,
          entryGroups,
          isCancelled: false,
        })
        const { entries: wasCancelled, mnemocodesToGroupKeys: mnemocodesToGroupKeysCancelled } = makeEntriesSlice({
          data,
          paymentCards,
          entryGroups,
          isCancelled: true,
        })

        const productGroups = { ...mnemocodesToGroupKeysRegular, ...mnemocodesToGroupKeysCancelled }

        return {
          data: { byMnemocode, wasCancelled, productGroups, activeEntriesOrder },
        }
      },
      providesTags: ['Entries'],
    }),
    authorizeEntry: build.mutation<null, AuthorizeEntryQueryParams>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)
        const { dispatch } = queryApi

        // todo: fix response format?
        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/aol/profile/${profile_mnemocode}/entry`,
              method: 'POST',
              data: args,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/entries/authorize`,
              method: 'POST',
              data: {
                entryNumber: args.entry_nr,
                entryClass: args.entry_class,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as { data: EntryWithMetadata }
        const resEntry = resData.data
        const statusFinal = resEntry.metadata?.authResult ?? resEntry.status

        if (resEntry.metadata?.authResult) {
          delete resEntry.metadata
        }

        const patchedEntry = { ...resEntry, status: statusFinal } as Entry
        const entryKey = resEntry.mnemocode

        const entryObj = { [entryKey]: patchedEntry }

        try {
          dispatch(
            entriesApi.util.updateQueryData('getEntries', undefined, (draft) => {
              Object.assign(draft.byMnemocode, entryObj)
              draft.activeEntriesOrder.push(patchedEntry.mnemocode)
            })
          )
        } catch (err) {
          return { error: err }
        }

        return { data: null }
      },
    }),
    registerEntry: build.mutation<null, RegisterEntryQueryParams>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        // todo: эта функция не работает для 36.6, разобраться?
        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/aol/profile/${profile_mnemocode}/entry/signup`,
              method: 'POST',
              data: args,
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/entries/register`,
              method: 'POST',
              data: {
                acceptDisclaimers: args.accept_disclaimers,
                entryClass: args.entry_class,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        return { data: null }
      },
      invalidatesTags: ['Entries'],
    }),
    replaceProgramNumber: build.mutation<null, ReplaceNumberQueryParams>({
      async queryFn(args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { mnemocode, replaceNumber } = args
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/microservice/metadata/entry/auth`,
              method: 'PUT',
              params: {
                profile_code: profile_mnemocode,
                mnemocode,
                replaceNumber,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/entries/replace-number`,
              method: 'PUT',
              params: {
                entryMnemocode: mnemocode,
                replaceNumber,
              },
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        return { data: null }
      },
      invalidatesTags: ['Entries'],
    }),
  }),
})

export const {
  useGetEntriesQuery,
  useReplaceProgramNumberMutation,
  useRegisterEntryMutation,
  useAuthorizeEntryMutation,
} = entriesApi

const entriesSelector = createSelector(entriesApi.endpoints.getEntries.select(), (result) => result.data)
export const activeEntriesSelector = createSelector(entriesSelector, (entries) => {
  let result: Entry[] = []

  if (!entries?.byMnemocode) {
    return result
  }

  result = entries.activeEntriesOrder.map((entryMnemocode) => entries.byMnemocode[entryMnemocode])

  return result
})
// todo refactor to array
export const cancelledEntriesSelector = createSelector(entriesSelector, (entries) => entries?.wasCancelled ?? {})
export const productGroupsSelector = createSelector(entriesSelector, (entries) => entries?.productGroups ?? {})

interface MakeEntriesSliceProps {
  data: Entry[]
  paymentCards: PaymentCardResponse[]
  entryGroups: EntryGroup[]
  isCancelled: boolean
}

function makeEntriesSlice({ data, paymentCards, entryGroups, isCancelled }: MakeEntriesSliceProps): {
  entries: EntryByIdMap
  mnemocodesToGroupKeys: Record<string, string>
  order: string[]
} {
  const dataWithoutExcluded = getNonExcludedEntries(data)
  const activeEntries = dataWithoutExcluded.filter(({ status }) => status !== 'C')
  const cancelledEntries = dataWithoutExcluded.filter(({ status }) => status === 'C')

  const currentEntries = !isCancelled ? activeEntries : cancelledEntries

  const entries: EntryByIdMap = {}
  for (const currentEntry of currentEntries) {
    entries[currentEntry.mnemocode] = currentEntry
  }

  if (!Object.keys(entries).length) return { entries: {}, mnemocodesToGroupKeys: {}, order: [] }

  const paymentAccountNumbers = paymentCards.map(({ accountNumber }) => accountNumber)
  const paymentAccountNumbersUnique = [...new Set(paymentAccountNumbers)]

  const paymentAccounts: Record<string, { main: string; additional: string[] }> = {}
  for (const account of paymentAccountNumbersUnique) {
    if (account) {
      const main = findParent(paymentCards, account)
      const additional = findChildren(paymentCards, account)

      if (main) {
        paymentAccounts[account] = {
          main,
          additional,
        }
      }
    }
  }

  const paymentCardsInfo: Record<string, { paymentCardType: PaymentCardType; account: string | null }> = {}
  for (const { mnemocode, mainCard, accountNumber } of paymentCards) {
    if (Boolean(accountNumber) && Boolean(mainCard) && (mainCard === 'Y' || mainCard === 'N')) {
      paymentCardsInfo[mnemocode] = {
        paymentCardType: mainCard === 'Y' ? 'main' : 'additional',
        account: accountNumber!,
      }
    } else {
      paymentCardsInfo[mnemocode] = {
        paymentCardType: 'common',
        account: null,
      }
    }
  }

  const pcEntriesMnemocodes = Object.values(entries)
    .filter(({ product_class }) => product_class === 'PC')
    .map(({ mnemocode }) => mnemocode)

  pcEntriesMnemocodes.forEach((mnemocode) => {
    const currentEntry = entries[mnemocode]
    const currentPaymentCard = paymentCardsInfo[mnemocode]
    const currentAccount = currentPaymentCard?.account && paymentAccounts[currentPaymentCard?.account]
    const currentCardType = currentPaymentCard?.paymentCardType ?? 'common'

    currentEntry.paymentCardType = currentCardType

    if (currentCardType === 'common') {
      currentEntry.childrenProducts = null
      currentEntry.parentEntryProduct = null
    }
    if (currentAccount && currentCardType === 'main') {
      currentEntry.childrenProducts = currentAccount?.additional ?? []
      currentEntry.parentEntryProduct = null
    }
    if (currentAccount && currentCardType === 'additional') {
      currentEntry.parentEntryProduct = currentAccount?.main
      currentEntry.childrenProducts = null
    }
  })

  const { processedGroups, entriesMnemocodesMain, entriesMnemocodesExcluded, mnemocodesToGroupKeys } =
    processEntryGroups(entryGroups, entries)
  const cardNames = getCardNames(paymentCards)

  entriesMnemocodesExcluded.forEach((mnemocode) => delete entries[mnemocode])
  entriesMnemocodesMain.forEach((mnemocode) => {
    const entry = entries[mnemocode]
    if (cardNames[mnemocode]) entry.corpName = cardNames[mnemocode]
    entry.paymentCardType = 'virtual'
    entry.childrenProducts = processedGroups[mnemocode] ?? null
  })

  const order = currentEntries
    .map((item) => item.mnemocode)
    .filter((mnemocode) => !entriesMnemocodesExcluded.includes(mnemocode))

  return { entries, mnemocodesToGroupKeys, order }
}

function processEntryGroups(groups: EntryGroup[], entries: EntryByIdMap) {
  const processedGroups: Record<string, string[]> = {}
  const mnemocodesToGroupKeys: Record<string, string> = {}

  const idToMnemocodes = getMnemocodesFromIds(entries)

  for (const group of groups) {
    const entryIds = [...new Set(group.entryIdList.entryIds)]
    const mainId = entryIds.shift()
    const { groupKey } = group
    const mnemocodeMain = mainId && idToMnemocodes[mainId]
    if (mnemocodeMain) {
      mnemocodesToGroupKeys[mnemocodeMain] = groupKey
      processedGroups[mnemocodeMain] = entryIds.map((id) => idToMnemocodes[id])
    }
  }
  const entriesMnemocodesMain = Object.keys(processedGroups)
  const allDependentIds = Object.values(processedGroups)
  const entriesMnemocodesExcluded = Array.prototype.concat.apply([], allDependentIds) as string[]

  return { processedGroups, entriesMnemocodesMain, entriesMnemocodesExcluded, mnemocodesToGroupKeys }
}

function getMnemocodesFromIds(entries: EntryByIdMap) {
  const result: Record<number, string> = {}
  Object.entries(entries).forEach(([key, entry]) => {
    result[entry.entry_id] = key
  })
  return result
}

function getCardNames(cards: PaymentCardResponse[]) {
  const cardNames: Record<string, string | null> = {}
  for (const { mnemocode, corpName } of cards) {
    cardNames[mnemocode] = corpName
  }
  return cardNames
}

function findParent(paymentCards: PaymentCardResponse[], accountNumber: string) {
  const parent = paymentCards
    .filter((pc) => pc.accountNumber === accountNumber)
    .find(({ mainCard }) => mainCard === 'Y')
  return parent?.mnemocode
}

function findChildren(paymentCards: PaymentCardResponse[], accountNumber: string) {
  return paymentCards
    .filter((pc) => pc.accountNumber === accountNumber)
    .filter(({ status }) => status !== 'C')
    .filter(({ mainCard }) => mainCard === 'N')
    .map(({ mnemocode }) => mnemocode)
}

function getNonExcludedEntries(data: Entry[]): Entry[] {
  const excludedProductsFromEntry = ['99990050', '99990046']
  const excludedEntryClasses = ['01147TF', '01145OTT']

  return data.filter(
    ({ product, entry_class }) =>
      !excludedProductsFromEntry.includes(product) && !excludedEntryClasses.includes(entry_class)
  )
}
