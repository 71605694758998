import styled from 'styled-components'

type ObjectFit = 'cover' | 'contain' | 'fill'
type ObjectPosition = 'top' | 'center' | 'bottom' | 'left' | 'right'

interface Props {
  src?: string
  alt?: string
  fit?: ObjectFit
  position?: ObjectPosition
  className?: string
  hasPerformantImgFormat?: boolean
}

const cdnBucketNameRegexp = /lmg-whitelabel-api(-\w{3})?/
const optimizedImagesExtensions = /jpg|jpeg|png/

export function Image({ src = '', alt, className, position, hasPerformantImgFormat, fit = 'cover' }: Props) {
  if (!src) return null

  const splitSrc = src.split('.')
  const srcWithoutExt = splitSrc.slice(0, -1).join('.')
  const ext = splitSrc.slice(-1)[0] || ''

  const shouldUsePerformantFormat =
    Boolean(srcWithoutExt) &&
    supportsWebP() &&
    (hasPerformantImgFormat || (optimizedImagesExtensions.test(ext) && cdnBucketNameRegexp.test(src)))

  return (
    <ImageWrapper className={className} fit={fit} position={position}>
      <img src={shouldUsePerformantFormat ? `${srcWithoutExt}.webp` : src} alt={alt} />
    </ImageWrapper>
  )
}

export const ImageWrapper = styled.div<{ fit: ObjectFit; position?: ObjectPosition }>`
  display: flex;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;

  & img {
    width: 100%;
    height: 100%;
    font-size: 6px;
    object-fit: ${({ fit = 'cover' }) => fit};
    object-position: ${({ position }) => Boolean(position) && position};
  }
`
