import styled, { css } from 'styled-components'

import { mobileMedia } from '~/lib/theme'

type Direction = 'top' | 'bottom' | 'left' | 'right'

export interface BreakProps {
  direction?: Direction
  indent: number
  indentMobile?: number
}

export const Break = styled.div<BreakProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  margin: ${({ direction, indent }) => getMargin(indent, direction)};

  ${mobileMedia} {
    ${({ indentMobile, direction }) =>
      indentMobile &&
      css`
        margin: ${getMargin(indentMobile, direction)};
      `}
  }
`

function getMargin(indent: number, direction: Direction = 'bottom') {
  switch (direction) {
    case 'top':
      return `${indent}px 0 0 0 !important`
    case 'right':
      return `0 ${indent}px 0 0 !important`
    case 'bottom':
      return `0 0 ${indent}px 0 !important`
    case 'left':
      return `0 0 0 ${indent}px !important`
    default:
      return '0'
  }
}
