import { ClientOption } from '~/models'
import { useSelector } from 'react-redux'
import { allOptionsSelector, clientOptionsSelector } from '~/store/api/options'
import { useIntl } from 'react-intl'

export function useGetCurrentOption(productMnemocode: string) {
  const intl = useIntl()
  const allClientOptions = useSelector(clientOptionsSelector)
  const allOptions = useSelector(allOptionsSelector)
  const clientOptions = allClientOptions[productMnemocode]

  const clientOption = clientOptions?.find(({ finishDate }) => finishDate === null) as ClientOption
  const currentOption = allOptions.find(({optionId}) => optionId === clientOption?.optionId)

  const currentOptionId = clientOption?.optionId
  const currentOptionName = intl.messages[`app.options.${currentOption?.mnemocode}.name`] as unknown as string

  return { currentOptionId, currentOptionName }
}
