import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { PlatformStatus, ProductModel, ProgramModel } from '~/models'
import { ProgramSelector } from '~/store/selector/programs'
import { activeEntriesSelector } from '~/store/api/entries'
import { ProductOptionSelector } from '~/store/selector/products'
import { useMemo } from 'react'

type ProductOf<T> = T extends string ? ProductModel : ProductModel[]

const productFallback: ProductModel = {
  entryClass: '',
  details: {
    cardName: '',
    icon: '',
  },
  features: [],
  entryName: '',
  entryStatus: PlatformStatus.NotAuthorized,
  sortingOrder: 1,
  entryMnemocode: '',
  pastProgram: undefined,
  presentProgram: undefined,
  futureProgram: undefined,
  pastOption: undefined,
  presentOption: undefined,
  futureOption: undefined,
  createdWhen: undefined,
  childrenProducts: [],
  paymentCardType: 'common',
  parentEntryProduct: null,
}

export const ProductProgramSelector = createSelector(
  ProductOptionSelector,
  ProgramSelector,
  addProgramEntryToProductOptions
)

function addProgramEntryToProductOptions(
  productOptionEntries: ProductModel[],
  programEntries: ProgramModel[]
): ProductModel[] {
  return productOptionEntries.map((productOptionEntry) => {
    const productProgramOptionEntry = {
      ...productOptionEntry,
    }

    if (productOptionEntry.pastOption) {
      productProgramOptionEntry.pastProgram = programEntries.find(
        (programEntry) => programEntry.optionsByProgram?.includes(productOptionEntry.pastOption!.productOptionId)
      )
    }
    if (productOptionEntry.presentOption) {
      productProgramOptionEntry.presentProgram = programEntries.find(
        (programEntry) => programEntry.optionsByProgram?.includes(productOptionEntry.presentOption!.productOptionId)
      )
    }
    if (productOptionEntry.futureOption) {
      productProgramOptionEntry.futureProgram = programEntries.find(
        (programEntry) => programEntry.optionsByProgram?.includes(productOptionEntry.futureOption!.productOptionId)
      )
    }

    return productProgramOptionEntry
  })
}

// TODO what if no entries were found?
export const useGetProducts = <T extends string | string[]>(mnemocode: T): ProductOf<T> => {
  const products = useSelector(ProductProgramSelector)

  if (Array.isArray(mnemocode)) {
    return products.filter(({ entryMnemocode }) => mnemocode.includes(entryMnemocode)) as ProductOf<T>
  }

  const currentProduct = products.find(({ entryMnemocode }) => mnemocode === entryMnemocode) as ProductOf<T>

  return currentProduct ?? (productFallback as ProductOf<T>)
}

export const useGetMyProducts = (): ProductOf<string[]> => {
  const entries = useSelector(activeEntriesSelector)
  const myProducts = useGetProducts(entries.map((entry) => entry.mnemocode))

  return useMemo(() => sortAndFilterProducts(myProducts), [myProducts])
}

function sortAndFilterProducts(productsRaw: ProductModel[]): ProductModel[] {
  if (!Array.isArray(productsRaw) || !productsRaw.length) return []

  const products = productsRaw.filter((product) => Boolean(product?.presentOption?.optionId))
  if (!products.length) return []

  const productsMainAndChildren: ProductModel[] = []

  const mainProducts = products.filter(({ paymentCardType }) => paymentCardType === 'main')
  mainProducts.forEach((mainProduct) => {
    productsMainAndChildren.push(mainProduct)

    const childrenProductsMnemocodes = mainProduct.childrenProducts ?? []
    childrenProductsMnemocodes.forEach((childrenMnemocode) => {
      const childrenProduct = products.find(({ entryMnemocode }) => childrenMnemocode === entryMnemocode)
      if (childrenProduct) productsMainAndChildren.push(childrenProduct)
    })
  })

  const productsRest = products.filter(
    ({ paymentCardType }) => paymentCardType === 'common' || paymentCardType === 'virtual'
  )

  return [...productsMainAndChildren, ...productsRest]
}
