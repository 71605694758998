import styled from 'styled-components'
import { IconType } from '~/models'
import { MessagePicture } from '@/MessagePicture'
import { typographyCss } from '@/Typography'
import { useIntl } from 'react-intl'
import { RestrictionType } from '~/models/settings'
import { useGetRestrictionsData } from '@/restrictions/useGetRestrictionsData'

interface Props {
  section: RestrictionType
}

const iconDefault: IconType = { name: 'Clock', color: 'red' }

export function RestrictionView({ section }: Props) {
  const { messages } = useIntl()
  const messageDefault = messages[`app.restrictions.${section}`] as unknown as string
  const { icon, message } = useGetRestrictionsData(section)

  return (
    <Container>
      <MessagePicture content={icon ?? iconDefault} />
      <TextContent dangerouslySetInnerHTML={{ __html: message ?? messageDefault }} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-row-gap: 24px;
  justify-items: center;
  padding: 0 40px;
  width: 100%;
`

const TextContent = styled.div`
  display: grid;
  grid-row-gap: 20px;
  text-align: center;
  justify-items: center;
  ${typographyCss.t2regular};

  & strong {
    ${typographyCss.t1bold};
  }
`
