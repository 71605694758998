import React, { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'

import { IconWireFrame } from '@/icon/IconWireFrame'
import { BtnActionsStyle } from '@/BtnActionsStyle'

const duration = 0.2

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  classname?: string
  brandColored?: boolean
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void
}

export function Checkbox({ checked, onChange, className, brandColored = false, ...checkboxProps }: CheckboxProps) {
  return (
    <AnimatePresence>
      <Field className={className}>
        <HiddenCheckbox {...checkboxProps} type="checkbox" checked={checked} onChange={onChange} />
        <CheckboxView brandColored={brandColored}>
          {checked && (
            <SignContainer
              key="sign"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'easeInOut', duration }}
            >
              <IconWireFrame icon="Check" size={18} strokeWidth={2} />
            </SignContainer>
          )}
        </CheckboxView>
      </Field>
    </AnimatePresence>
  )
}

const Field = styled.label`
  width: 24px;
  height: 24px;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  ${BtnActionsStyle};
`

const HiddenCheckbox = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  clip: rect(0 0 0 0);
`

const CheckboxView = styled.span<{ brandColored: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  border-width: 1px;
  border-radius: 4px;
  border-style: solid;
  background-color: #fff;
  border-color: #dfe2f2;
  transition-duration: ${duration}s;
  transition-timing-function: ease-in-out;
  transition-property: border-color, background-color;

  input:checked + & {
    background-color: ${({ brandColored, theme }) => (brandColored ? theme.brand_color : '#e5f6eb')};
    border-color: ${({ brandColored, theme }) => (brandColored ? theme.brand_color : '#76c88e')};

    & svg {
      stroke: ${({ brandColored }) => (brandColored ? '#fff' : '#76c88e')};
    }
  }
`

const SignContainer = styled(motion.div)`
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`
