import styled from 'styled-components'
import { IconColorType, IconType } from '~/models'
import { IconWireFrame } from '@/icon/IconWireFrame'
import { colors } from '~/lib/theme'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'

interface Props {
  content: IconType | null
}

export function MessagePicture({ content }: Props) {
  const { theme } = useSelector(whiteLabelSelector)
  const { brand_color } = theme

  const iconColors: Record<IconColorType, keyof typeof colors | typeof brand_color> = {
    red: colors.red,
    green: colors.green,
    brand: brand_color,
  }

  if (!content) return null

  const contentIsImageSrc = typeof content === 'string'

  return (
    <Root>
      {contentIsImageSrc && <img src={content} alt="content" />}
      {!contentIsImageSrc && 'name' in content && (
        <IconWireFrame
          icon={content.name}
          color={content.color && iconColors[content.color]}
          size={72}
          strokeWidth={1}
        />
      )}
    </Root>
  )
}

const Root = styled.div`
  width: 72px;
  height: 72px;
  display: flex;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
