import styled from 'styled-components'
import { Grid } from '@/Grid'

export const Container = styled(Grid)`
  width: 100%;
  flex-grow: 1;
  max-width: 100%;
`

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 24px;
  text-align: center;
`

export const SignupNotice = styled.span`
  margin-top: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: 0.02em;

  color: #000000;

  & a {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.brand_color};
    }
  }
`
