import { useSelector } from 'react-redux'
import { activeEntriesSelector } from '~/store/api/entries'
import { useGetAllOptionsQuery, useGetClientOptionsQuery, useGetProductOptionsQuery } from '~/store/api/options'

export function useGetOptions() {
  const entries = useSelector(activeEntriesSelector)
  const hasEntries = Boolean(entries.length)

  const { isSuccess: isOptionsLoaded } = useGetAllOptionsQuery()
  const { isSuccess: isClientOptionsLoaded } = useGetClientOptionsQuery(undefined, {
    skip: !hasEntries,
  })
  const { isSuccess: isProductOptionsLoaded } = useGetProductOptionsQuery(undefined, {
    skip: !hasEntries,
  })

  return isOptionsLoaded && isClientOptionsLoaded && isProductOptionsLoaded
}
