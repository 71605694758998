import { memo } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

type Status = 'auth' | 'loading' | 'error' | 'dataLoading'

interface Props {
  status: Status
}

const LoginStatusMessageView = ({ status }: Props) => {
  const intl = useIntl()
  return <StyledMessage>{intl.messages[`app.login.status_messages.${status}`] as unknown as string}</StyledMessage>
}

export const LoginStatusMessage = memo(LoginStatusMessageView)

export const StyledMessage = styled.span`
  display: flex;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  margin: auto;
  text-transform: uppercase;
`
