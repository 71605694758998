import { combineReducers } from '@reduxjs/toolkit'

import authentication from './slices/authentication'
import process from './slices/process'
import options from './slices/options'
import settings from './slices/settings'
import whitelabel from './slices/whitelabel'
import { productApi } from './api/products'
import { programApi } from './api/programs'
import { entriesApi } from './api/entries'
import { offersApi } from './api/offers'
import { profileApi } from './api/profile'
import { balanceApi } from './api/balance'
import { miscApi } from './api/misc'
import { optionsApi } from './api/options'
import modal from './slices/modal'

const rootReducer = combineReducers({
  modal,
  authentication,
  process,
  options,
  settings,
  whitelabel,
  [productApi.reducerPath]: productApi.reducer,
  [programApi.reducerPath]: programApi.reducer,
  [entriesApi.reducerPath]: entriesApi.reducer,
  [offersApi.reducerPath]: offersApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [balanceApi.reducerPath]: balanceApi.reducer,
  [miscApi.reducerPath]: miscApi.reducer,
  [optionsApi.reducerPath]: optionsApi.reducer,
})

export default rootReducer
