import { useIntl } from 'react-intl'
import { useGetPrograms } from '~/hooks/useGetPrograms'

export function useGetProgramStatusText(mnemocode: string, returnUnauthorized?: boolean) {
  const intl = useIntl()
  const program = useGetPrograms(mnemocode)

  if (!mnemocode || !program || (!program.entryStatus && !returnUnauthorized)) return null

  return intl.formatMessage({
    id: `app.program.program_list.text_by_status_code.${program.entryStatus}`,
  })
}
