import styled from 'styled-components'
import { ReactNode } from 'react'
import { Scrollbars } from 'rc-scrollbars'

interface Props {
  children: ReactNode
  className?: string
  isAutoHeight?: boolean
  autoHeightMax?: number | string
  autoHeightMin?: number | string
}

export function ScrollbarWrapper({ children, className, isAutoHeight, autoHeightMax, autoHeightMin }: Props) {
  return (
    <Container className={className}>
      <Scrollbars autoHide autoHeight={isAutoHeight} autoHeightMax={autoHeightMax} autoHeightMin={autoHeightMin}>
        {children}
      </Scrollbars>
    </Container>
  )
}

/*
  There are some overrides of "rc-scrollbars" styles.
  This was necessary because when trying to auto-set the height of a scrollbar component using the "autoHeight" prop,
  this library sets the maximum height to 200px (this value can only be changed to another fixed value).
*/
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & .rc-scrollbars-container {
    flex-grow: 1;
  }

  & .rc-scrollbars-view {
    display: flex;
    flex-direction: column;
  }
`
