import styled from 'styled-components'
import { IconWireFrame } from '@/icon/IconWireFrame'

export type Direction = 'right' | 'left' | 'up' | 'down'

interface Props {
  size?: number
  color?: string
  className?: string
  isDisabled?: boolean
  strokeWidth?: number
  direction?: Direction
  containerSize?: number
  onClick?: () => void
}

export function AngleArrow({
  className,
  isDisabled,
  containerSize,
  size = 24,
  color = '#242424',
  strokeWidth = 1.8,
  direction = 'right',
  onClick,
}: Props) {
  return (
    <Container
      onClick={onClick}
      className={className}
      $size={containerSize}
      $direction={direction}
      as={onClick && 'button'}
      $isDisabled={isDisabled || !onClick}
    >
      <IconWireFrame icon="Chevron" strokeWidth={strokeWidth} color={color} size={size} />
    </Container>
  )
}

const directionAngles: Record<Direction, number> = {
  right: 0,
  down: 90,
  left: 180,
  up: 270,
}

const Container = styled.div<{
  $size?: number
  $isDisabled: boolean
  $direction: Direction
}>`
  display: flex;
  cursor: pointer;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  width: ${({ $size }) => $size && `${$size}px`};
  height: ${({ $size }) => $size && `${$size}px`};
  pointer-events: ${({ $isDisabled }) => $isDisabled && 'none'};
  transform: ${({ $direction }) => $direction !== 'right' && `rotate(${directionAngles[$direction]}deg)`};

  & svg {
    transition: stroke 0.2s ease-in-out;
  }
`
