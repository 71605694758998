import { useGetMyProducts } from '~/hooks/useGetProducts'
import { useGetPrograms } from '~/hooks/useGetPrograms'

interface Props {
  programMnemocode: string
}

export function useGetProductsForProgramLink({ programMnemocode }: Props) {
  const program = useGetPrograms(programMnemocode)
  const myProducts = useGetMyProducts()

  const { futureProduct, presentProduct, availableProducts } = program

  const activeProducts = [...presentProduct!, ...futureProduct!]
  const activeProductsMnemocodes = activeProducts.map(({ entryMnemocode }) => entryMnemocode)

  const availableForProgramMyProducts = myProducts.filter(({ entryClass }) => availableProducts.includes(entryClass))

  const readyForLinkProducts = availableForProgramMyProducts.filter(
    ({ entryMnemocode }) => !activeProductsMnemocodes.includes(entryMnemocode)
  )

  return { activeProducts, readyForLinkProducts }
}
