import { ReactNode } from 'react'
import Head from 'next/head'
import { useIntl } from 'react-intl'

interface Props {
  children: ReactNode
}

export function HeadWrapper({ children }: Props) {
  const { messages } = useIntl()
  const siteTitle = messages[`app.site_title`] as unknown as string

  return (
    <>
      <Head>
        <link rel="icon" href={`${process.env.ASSET_PREFIX}/favicon.png`} />
        {siteTitle && <title>{siteTitle}</title>}
      </Head>
      {children}
    </>
  )
}
