export default function AkbarsOffline() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <span>🚧</span>
      <span>Раздел недоступен. При возникновении вопросов обращайтесь в поддержку Банка.</span>
    </div>
  )
}
