import React, { MouseEvent, useCallback, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useSnapshot, proxy } from 'valtio'
import { AnimatePresence } from 'framer-motion'
import { mobileVerticalMargin } from '~/components/layout/content/blocksSizes'
import { desktopMedia } from '~/lib/theme'
import { WithChildren } from '~/models'
import { typographyCss } from '@/Typography'
import { CloseButton } from '@/CloseButton'
import { FadeBackground } from '@/FadeBackground'
import { Portal } from '@/Portal'

export const modalState = proxy<{ activeModalId?: string | number }>({ activeModalId: undefined })

export type ModalPositionCommon = 'BOTTOM' | 'MIDDLE' | 'TOP' | 'FULLSCREEN'
export type ModalMobilePosition = Exclude<ModalPositionCommon, 'TOP'>

interface UseModalProps {
  onModalClose?: () => void
  isVisibleOnMount?: boolean
  modalId?: string | number
}

export function useModal({ onModalClose, isVisibleOnMount, modalId }: UseModalProps) {
  const { activeModalId } = useSnapshot(modalState)

  const modalIsVisible = activeModalId === modalId

  const showModal = useCallback(() => {
    if (!modalId || activeModalId) return

    modalState.activeModalId = modalId
  }, [modalId, activeModalId])

  const closeModal = useCallback(() => {
    if (!activeModalId) return

    modalState.activeModalId = undefined

    if (typeof onModalClose === 'function') onModalClose()
  }, [activeModalId, onModalClose])

  useEffect(() => {
    if (isVisibleOnMount) showModal()
  }, [isVisibleOnMount, showModal])

  return {
    showModal,
    closeModal,
    modalIsVisible,
  }
}

const modalPositionsIsWindowedOnMobile: ModalMobilePosition[] = ['BOTTOM', 'MIDDLE']

interface ModalPropsEnhanced extends WithChildren {
  onClose: () => void
  hasNoTitle?: boolean
  showCloseButtonOnMobile?: boolean
  positionOnMobile?: ModalMobilePosition
  isVisible: boolean
  desktopWidth?: number
}

export function Modal({
  children,
  showCloseButtonOnMobile,
  onClose,
  isVisible,
  hasNoTitle,
  positionOnMobile = 'FULLSCREEN',
  desktopWidth = 414,
}: ModalPropsEnhanced) {
  const disableReactionOnClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const isWindowedOnMobile = modalPositionsIsWindowedOnMobile.includes(positionOnMobile)

  return (
    <AnimatePresence>
      {isVisible && (
        <Portal onEscKeyPressed={onClose}>
          <FadeBackground onClick={onClose} hideFadeOnMobile={!isWindowedOnMobile}>
            <Container
              $hasPaddingTop={Boolean(hasNoTitle)}
              $modalPositionMobile={positionOnMobile}
              $desktopWidth={desktopWidth}
              onClick={disableReactionOnClick}
            >
              <CloseButtonStyled hideOnMobile={!showCloseButtonOnMobile} onClick={onClose} sizeContainer={32} />
              {children}
            </Container>
          </FadeBackground>
        </Portal>
      )}
    </AnimatePresence>
  )
}

export const ModalTitle = styled.div`
  ${typographyCss.t1bold};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 56px;
  text-align: center;
  padding: 12px 40px;
  margin-bottom: 16px;
`

const mobileModalPositions: Record<ModalMobilePosition, ReturnType<typeof css>> = {
  BOTTOM: css`
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
    max-height: 90vh;
  `,
  MIDDLE: css`
    left: ${mobileVerticalMargin}px;
    right: ${mobileVerticalMargin}px;
    max-height: 90vh;
  `,
  FULLSCREEN: css`
    left: 0;
    right: 0;
    bottom: 0;
    top: ${({ theme }) => theme.header.height}px;
    border-radius: unset;
  `,
}

const Container = styled.div<{
  $modalPositionMobile: ModalMobilePosition
  $hasPaddingTop: boolean
  $desktopWidth: number
}>`
  --modal-width: ${({ $modalPositionMobile }) =>
    $modalPositionMobile === 'MIDDLE' ? `100vw - ${mobileVerticalMargin * 2}px` : '100vw'};
  --modal-paddings-h: 16px;
  --modal-content-max-width: calc(var(--modal-width) - var(--modal-paddings-h) * 2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  cursor: auto;
  position: fixed;
  pointer-events: all;
  width: var(--modal-width);
  padding: ${({ $hasPaddingTop }) => ($hasPaddingTop ? '56px' : 0)} var(--modal-paddings-h) 16px var(--modal-paddings-h);
  ${({ $modalPositionMobile }) => mobileModalPositions[$modalPositionMobile]};

  ${desktopMedia} {
    --modal-width: ${({ $desktopWidth }) => $desktopWidth}px;
    position: relative;
    border-radius: 16px;
    max-height: unset;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
  }
`

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  right: 16px;
  top: 12px;
  z-index: 1;
`
