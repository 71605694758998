import { createContext } from 'react'

export interface AnalyticsContextProps {
  analyticsIdCommon: number | null
  analyticsIdPartner: number | null
}

export const AnalyticsIDContext = createContext<AnalyticsContextProps>({
  analyticsIdCommon: null,
  analyticsIdPartner: null,
})
