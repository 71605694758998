import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { useSetStepActions } from '@/MultiStepModal/hooks/useSetStepActions'
import { BtnActionsStyle } from '@/BtnActionsStyle'
import { useCallback } from 'react'
import { useMetrika } from '~/hooks/useMetrika'

interface Props {
  programName: string
}

export function GoToRegistrationBtn({ programName }: Props) {
  const { messages } = useIntl()
  const { setNextStep } = useSetStepActions()
  const { reachGoal } = useMetrika()
  const clickHandler = useCallback(() => {
    setNextStep()
    reachGoal({ target: 'goToRegistration', params: { goToRegistration_Program: programName } })
  }, [programName, reachGoal, setNextStep])

  return (
    <Container>
      <Action onClick={clickHandler}>{messages[`app.program.registration.btn_text_first`] as unknown as string}</Action>
    </Container>
  )
}

const Container = styled.div`
  margin: 16px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  font-size: 14px;
  min-height: 24px;
  position: relative;
`

const Action = styled.button`
  height: 100%;
  text-decoration: underline;
  font-weight: 700;
  color: ${({ theme }) => theme.brand_color};
  ${BtnActionsStyle};
`
