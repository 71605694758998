export const menuWidth = 320
export const sideBlockWidth = 330
export const mainBlockWidth = 778
export const mainBlockIsSingleWidth = 824
export const gutter = 24

export const menuSpace = menuWidth + gutter
export const sideBlockSpace = sideBlockWidth + gutter

export const marginVertical = 40
export const marginVerticalMob = 24

export const modalTopDesktopHeight = 42

export const mobileVerticalMargin = 16
