import styled, { css } from 'styled-components'
import { Button, type ButtonProps } from '@/Button'

interface Props extends ButtonProps {
  isSuccess: boolean
}

export function ButtonWithSuccessState({ isSuccess, children, ...buttonProps }: Props) {
  return (
    <ButtonStyled $isSuccess={isSuccess} {...buttonProps}>
      {children}
    </ButtonStyled>
  )
}

const ButtonStyled = styled(Button)<{ $isSuccess?: boolean }>`
  ${({ $isSuccess }) =>
    $isSuccess &&
    css`
      &:disabled {
        background-color: #0ea339;
        border-color: #0ea339;
        color: #fff;
      }
    `};
`
