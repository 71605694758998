import { ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DefaultTheme, ThemeProvider, StyleSheetManager } from 'styled-components'
import isPropValid from '@emotion/is-prop-valid'
import { colors } from '~/lib/theme'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { GlobalStyle } from '~/lib/globalStyles'

interface Props {
  children: ReactNode
}

export function StyledComponentsWrapper({ children }: Props) {
  const { theme } = useSelector(whiteLabelSelector)
  const themeColors: DefaultTheme = useMemo(() => ({ ...theme, colors }), [theme])

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ThemeProvider theme={themeColors}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  )
}

function shouldForwardProp(propName: string, target: unknown) {
  if (typeof target === 'string') return isPropValid(propName)
  return true
}
