import { processActions } from '~/store/slices/process'
import { useCallback } from 'react'
import { useActionCreators } from '~/store/redux-store'

export function useSetStepActions() {
  const { setStep } = useActionCreators(processActions)

  const setPrevStep = useCallback(() => {
    setStep({ direction: 'prev' })
  }, [setStep])

  const setNextStep = useCallback(() => {
    setStep({ direction: 'next' })
  }, [setStep])

  return { setPrevStep, setNextStep }
}
