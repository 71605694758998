import styled from 'styled-components'
import { DropdownType } from '../types'

export const Text = styled.span<{ $type: DropdownType; $isSelected?: boolean; $isDisabled?: boolean }>`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
  transition: color 0.2s ease-in-out;
  text-align: ${({ $type }) => $type === 'simple' && 'center'};
  color: ${({ $isSelected, $isDisabled, theme }) => {
    switch (true) {
      case $isDisabled:
        return '#bbb'
      case $isSelected:
        return theme.brand_color
      default:
        return '#242424'
    }
  }};
`
