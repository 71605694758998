/* eslint-disable max-len */
import styled from 'styled-components'

import { smallDesktopMedia } from '~/lib/theme'

import { BtnActionsStyle } from '@/BtnActionsStyle'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'

interface BurgerProps {
  color?: string
  onBurgerClick: () => void
  size?: number
}

const defaultBurgerHeight = 48

export function Burger({ color, onBurgerClick, size }: BurgerProps) {
  const { theme } = useSelector(whiteLabelSelector)
  const signColor = color || theme.brand_color || '#000'
  const burgerSize = size ?? defaultBurgerHeight

  return (
    <Container onClick={onBurgerClick} size={burgerSize}>
      <Inner>
        <svg width={burgerSize} height={burgerSize} fill={signColor} viewBox="0 0 48 48">
          <path d="M12 17a1 1 0 011-1h22a1 1 0 110 2H13a1 1 0 01-1-1zM12 24a1 1 0 011-1h22a1 1 0 110 2H13a1 1 0 01-1-1zM12 31a1 1 0 011-1h22a1 1 0 110 2H13a1 1 0 01-1-1z" />
        </svg>
      </Inner>
    </Container>
  )
}

const Container = styled.button<{ size: number }>`
  display: none;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  flex-shrink: 0;

  ${BtnActionsStyle};

  ${smallDesktopMedia} {
    display: flex;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`
