import * as React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import { Direction } from '~/models'
import { useGetCurrentStep } from '../hooks/useGetCurrentStep'

const variants = {
  enter: (direction: Direction) => {
    return {
      x: direction === 'next' ? 380 : -380,
      opacity: 0,
    }
  },
  center: {
    x: 0,
    opacity: 1,
    zIndex: 1,
  },
  exit: (direction: Direction) => {
    return {
      x: direction === 'prev' ? 380 : -380,
      opacity: 0,
      zIndex: 0,
    }
  },
}

const transition = {
  x: { type: 'spring', stiffness: 1000, damping: 99 },
  opacity: { duration: 0.2 },
}

interface AnimatedContainerProps {
  animationKey: string
  children: React.ReactNode
}

export function AnimatedContainer({ children, animationKey }: AnimatedContainerProps) {
  const { direction } = useGetCurrentStep()

  return (
    <Container
      key={animationKey}
      initial="enter"
      animate="center"
      exit="exit"
      custom={direction}
      variants={variants}
      transition={transition}
    >
      {children}
    </Container>
  )
}

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`
