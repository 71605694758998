import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { useGetProductOptionSettings } from '~/hooks/useIsConflictOption'
import { useGetDefaultOption } from '~/hooks/useGetDefaultOption'
import { useRouter } from 'next/router'
import { allOptionsSelector, optionsByProgramSelector, useSetProductOptionMutation } from '~/store/api/options'
import { ByProgram, ProductModel } from '~/models'
import { useGetProducts } from '~/hooks/useGetProducts'
import { optionsActions } from '~/store/slices/options'

export function useLinkProducts() {
  const router = useRouter()
  const { setFirstOptionChangingStatus,  } = useActionCreators(optionsActions)
  const [setProductOptionMutation] = useSetProductOptionMutation()

  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)

  const productMnemocode = selectedCard ?? (router.query.card as string)
  const selectedProductOptionId = useGetProductOptionSettings(productMnemocode)
  const defaultProductOptionId = useGetDefaultOption()
  const productOptionId = defaultProductOptionId ?? selectedProductOptionId

  const byProgram = useSelector(optionsByProgramSelector)
  const allOptions = useSelector(allOptionsSelector)
  const product = useGetProducts(productMnemocode) as ProductModel

  const pcMnemocodes = useMemo(() => {
    const childrenProducts = product.childrenProducts ?? []

    return [productMnemocode, ...childrenProducts]
  }, [product.childrenProducts, productMnemocode])

  const allProductOptions = Object.values(byProgram)
  const allProductOptionsFlatten = Array.prototype.concat.apply([], allProductOptions) as ByProgram[]
  const currentProductOption = allProductOptionsFlatten.find(
    ({ product_option_id }) => product_option_id === productOptionId
  )
  const currentOptionId = currentProductOption?.optionId
  const currentOption = allOptions.find(({ optionId }) => optionId === currentOptionId)

  const loyaltyEntryClassName = currentProductOption?.loyalty_code
  const optionMnemocode = currentOption?.mnemocode

  const linkProducts = useCallback(
    (isFirstOptionChange?: boolean) => {
      if(!loyaltyEntryClassName || !optionMnemocode) return
      if (isFirstOptionChange) setFirstOptionChangingStatus(true)

      setProductOptionMutation({
        loyaltyEntryClassName,
        optionMnemocode,
        pcMnemocodes,
      })
    },
    [loyaltyEntryClassName, optionMnemocode, pcMnemocodes, setFirstOptionChangingStatus, setProductOptionMutation]
  )

  return {
    linkProducts,
  }
}
