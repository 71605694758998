import { nanoid } from 'nanoid'
import qs from 'query-string'
import { v4 as uuidv4 } from 'uuid'
import { save, load, isExists } from '~/lib/sessionStorage'

const provider_id = 5
const client_id = 'GoPoints'

type QueryParams = {
  client_id: string
  state: string
  user_token: string
  redirect_uri?: string
}

export const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

export function getAuthWindowParams(user_token: string, oauthUrl: string, showRedirectUri: boolean) {
  const state = nanoid()
  const redirect_uri = `${document.location.origin}/oauth_redirect.html`

  const queryParams: QueryParams = {
    client_id,
    state,
    user_token,
  }

  if (showRedirectUri) queryParams.redirect_uri = redirect_uri

  const params = qs.stringify(queryParams)
  const url = `${oauthUrl}&${params}`

  return { url, windowName: state }
}

export function getAuthProcessParams(data: string) {
  const { url: redirect_uri, query } = qs.parseUrl(data)

  const { code: queryCode } = query
  const code = queryCode as string

  const queryParams = {
    code,
    redirect_uri,
    provider_id,
  }

  return qs.stringify(queryParams)
}

const loginKey = '@userLogin'

export function setUUID() {
  if (isExists(loginKey)) return

  const uuid = uuidv4()
  save(loginKey, { uuid })
}

export function getUUID() {
  if (!isExists(loginKey)) return

  const { uuid } = load(loginKey) as { uuid: string }
  // eslint-disable-next-line consistent-return
  return uuid
}
