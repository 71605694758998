import { format, toDate, Locale } from 'date-fns'

interface Props {
  inputDate?: string | Date
  outputFormat?: string
  locale: Locale
}

export function getDateIntl({ inputDate, outputFormat = 'LLLL', locale }: Props) {
  if (!inputDate) return ''
  const date = typeof inputDate === 'string' ? toDate(new Date(inputDate)) : inputDate

  return format(date, outputFormat, { locale })
}
