import { css } from 'styled-components'
import { mediaHover } from '~/lib/theme'

export const BtnActionsStyle = css`
  cursor: pointer;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;

  ${mediaHover} {
    &:hover {
      filter: brightness(90%);
    }
  }

  &:active {
    filter: brightness(110%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover,
    &:active {
      filter: unset;
    }
  }
`
