import { MultiStepModal } from '@/MultiStepModal/MultiStepModal'
import { useSetStepActions } from '@/MultiStepModal/hooks/useSetStepActions'
import { useRouter } from 'next/router'
import { ProcessComponent } from '~/models'
import { Authorization } from './components/Authorization'
import { Registration } from './components/Registration'
import { SavingChanges } from './components/SavingChanges'

export function CardSettingsConfirmFlow() {
  const router = useRouter()
  const productMnemocode = router.query.card as string
  const { setNextStep } = useSetStepActions()

  const getComponent = ({ component }: { component: ProcessComponent }) => {
    switch (component) {
      case 'authorization':
        return <Authorization onNextClick={setNextStep} />
      case 'registration':
        return <Registration onNextClick={setNextStep} />
      case 'savingChanges':
        return <SavingChanges changedProductId={productMnemocode} useAnotherStore />
      default:
        return null
    }
  }

  return <MultiStepModal getComponent={getComponent} />
}
