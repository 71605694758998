import { useEffect } from 'react'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { redirectTo } from '~/lib/api/redirectBus'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'

export function useBackBtnService() {
  const router = useRouter()
  const path = usePathname()
  const { bankSpecificFeatures } = useSelector(whiteLabelSelector)
  const backButtonRedirectsToHome = bankSpecificFeatures.includes('backButtonRedirectsToHome')

  const isOnHome = Boolean(path) && path.includes('home')
  const isOnAuthError = Boolean(path) && path.includes('auth-error')

  const redirectStdAddress = path ? `${path}?close_window=true` : ''

  const isOnHistoryFilters = Boolean(path) && path.includes('/filters')
  const addressOfBaseHistoryPage = isOnHistoryFilters ? path.split('/filters')[0] : null

  useEffect(() => {
    router.beforePopState(() => {
      if (isOnHome || isOnAuthError) {
        redirectTo.address = redirectStdAddress
        return false
      }
      if (backButtonRedirectsToHome) {
        redirectTo.address = '/home'
        return false
      }
      if (isOnHistoryFilters) {
        redirectTo.address = addressOfBaseHistoryPage
        return false
      }
      return true
    })
  }, [
    addressOfBaseHistoryPage,
    backButtonRedirectsToHome,
    isOnAuthError,
    isOnHistoryFilters,
    isOnHome,
    redirectStdAddress,
    router,
  ])
}
