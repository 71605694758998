import { ReactNode } from 'react'
import { useBackBtnService } from './useBackBtnService'
import { useRedirectService } from './useRedirectService'
import { useHistoryPagePathService } from './useHistoryPagePathService'
import { useCompatibilityCheckers } from './useCompatibilityCheckers'

interface Props {
  children: ReactNode
}

export function Services({ children }: Props) {
  useBackBtnService()
  useRedirectService()
  useHistoryPagePathService()
  useCompatibilityCheckers()

  return children
}
