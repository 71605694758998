import { useGetRestrictionsQuery } from '~/store/api/misc'
import { RestrictionType } from '~/models/settings'

export function useGetRestrictionsData(section: RestrictionType) {
  const { data, isSuccess: restrictionsIsLoaded } = useGetRestrictionsQuery()

  if (!restrictionsIsLoaded || (restrictionsIsLoaded && data && !data[section])) {
    return {
      restrictionsIsLoaded,
      restrictionIsActive: false,
    }
  }

  return {
    restrictionsIsLoaded,
    restrictionIsActive: data[section].isRestricted,
    icon: data[section].icon,
    message: data[section].message,
  }
}
