import { useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSnapshot } from 'valtio'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { useModal, Modal, modalState } from '@/Modal'
import { processActions } from '~/store/slices/process'
import { ProgramLinkFlow } from '~/components/flows/ProgramLinkFlow'
import { AuthorizationFlow } from '~/components/flows/AuthorizationFlow'
import { CardSettingsConfirmFlow } from '~/components/flows/CardSettingsConfirmFlow'
import { VoidFlow } from '~/components/flows/VoidFlow'

const modalId = 'processListener'

export function ProcessListener() {
  const { resetProcess } = useActionCreators(processActions)
  const router = useRouter()
  const process = useTypedSelector((state) => state.process.process)

  const { showModal, modalIsVisible, closeModal } = useModal({ modalId })
  const { activeModalId } = useSnapshot(modalState)

  const closeModalHandler = useCallback(() => {
    resetProcess()
  }, [resetProcess])

  useEffect(() => {
    if (process !== null) {
      showModal()
      return
    }
    if (activeModalId === modalId) {
      closeModal()
    }
  }, [closeModal, activeModalId, process, showModal])

  useEffect(() => {
    if (!modalIsVisible) return

    router.events.on('routeChangeStart', closeModalHandler)

    return () => {
      router.events.off('routeChangeStart', closeModalHandler)
    }
  }, [closeModalHandler, modalIsVisible, router.events])

  return (
    <Modal showCloseButtonOnMobile isVisible={modalIsVisible} onClose={closeModalHandler}>
      {process === 'authorization' && <AuthorizationFlow />}
      {process === 'link' && <ProgramLinkFlow />}
      {process === 'cardSettingsConfirm' && <CardSettingsConfirmFlow />}
      {process === null && <VoidFlow />}
    </Modal>
  )
}
