import { useCallback } from 'react'
import styled from 'styled-components'
import { Checkbox } from '@/checkbox'
import { useGetProducts } from '~/hooks/useGetProducts'
import { Grid } from '@/Grid'
import { Image } from '@/Image'
import { useIntl } from 'react-intl'

interface ProductProps {
  productMnemocode: string
  currentProgramName: string
  selected: boolean
  onSelect?: (entryClass: string) => void
  onBack?: () => void
}

export function Product({ productMnemocode, currentProgramName, selected, onSelect, onBack }: ProductProps) {
  const intl = useIntl()
  const product = useGetProducts(productMnemocode)

  const { entryName, details, presentProgram } = product
  const { icon, cardName } = details
  const alreadyLinkedProgramName = presentProgram?.details.name

  const handleSelect = useCallback(() => {
    if (onSelect && productMnemocode) onSelect(productMnemocode)
  }, [productMnemocode, onSelect])

  const programName = selected ? currentProgramName : alreadyLinkedProgramName

  return (
    <Container>
      {onSelect && <Checkbox checked={selected} onChange={handleSelect} />}
      <ProductLogo src={icon} alt={cardName} fit="contain" />
      <Texts>
        <ProductName>{entryName}</ProductName>
        {!onSelect && (
          <ProgramName selected={false}>
            {' '}
            {alreadyLinkedProgramName}
            {'->'}
          </ProgramName>
        )}
        <ProgramName selected={selected}>{programName}</ProgramName>
        {onBack && (
          <BackLink onClick={onBack}>
            {intl.messages[`app.program.change_program_choose`] as unknown as string}
          </BackLink>
        )}
      </Texts>
    </Container>
  )
}
export const BackLink = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #67616a;
  cursor: pointer;
  position: relative;

  & :before {
    content: '';
    height: 6px;
    width: 6px;
    border-left: 2px solid #67616a;
    border-bottom: 2px solid #67616a;
    position: absolute;
    left: -10px;
    bottom: 7px;
    transform: rotate(45deg);
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: unset;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

export const ProductLogo = styled(Image)`
  width: 64px;
  height: 40px;
  border-radius: 4px;
  margin: 0 16px;
`

export const Texts = styled(Grid)`
  font-size: 12px;
  user-select: text;
  color: #03030b;
`

export const ProductName = styled.span`
  font-weight: 700;
  letter-spacing: 0.2px;
`

export const ProgramName = styled.span<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  color: ${({ selected }) => selected && '#55bb73'};
`
