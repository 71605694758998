import { AnyAction, AsyncThunk } from '@reduxjs/toolkit'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk['pending']>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>
// type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>

export function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.endsWith('/pending')
}

export function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected')
}
