import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { BannerContent, MessageContent, ModalState, InfoTop, UnreadMessage } from '~/models'
import { AppearancesCount } from '~/components/modalMessage/types'

const initialState: ModalState = {
  messages: {},
  unread: [],
  read: [],
  banners: [],
  infoTop: null
}

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setMessages(state: ModalState, { payload }: PayloadAction<MessageContent[]>) {
      const messages: Record<number, MessageContent> = {}
      for (const message of payload) {
        messages[message.id] = message
      }
      state.messages = messages
    },
    setUnreadMessages(state: ModalState, { payload }: PayloadAction<{ route: string; appearancesCount: AppearancesCount }>) {
      const { route, appearancesCount } = payload
      state.unread = makeUnread(route, Object.values(state.messages), state.read, appearancesCount)
    },
    setMessageRead(state: ModalState, { payload }: PayloadAction<number>) {
      state.unread = state.unread.filter(({ id }) => id !== payload)
      state.read = [...state.read, payload]
    },
    setBanners(state: ModalState, { payload }: PayloadAction<BannerContent[]>) {
      state.banners = payload
    },
    setInfoTop(state: ModalState, { payload }: PayloadAction<InfoTop>) {
      state.infoTop = payload
    },
    resetInfoTop(state: ModalState) {
      state.infoTop = null
    }
  },
})

export const modalActions = slice.actions
export default slice.reducer

function makeUnread(route: string, messages: MessageContent[], read: number[], countViewed: AppearancesCount) {
  const filteredUnread = messages.filter(({ id }) => !read.includes(id))

  const filteredByAppearancesCount = filteredUnread.filter(({ id, appearancesCount }) => {
    if (!appearancesCount) return true
    const alreadyViewed = countViewed[id] ?? 0
    return alreadyViewed < appearancesCount
  })

  const onEnterMessages: UnreadMessage[] = filteredByAppearancesCount
    .filter(({ appearOnEnter }) => appearOnEnter)
    .map(({ id, position }) => ({ id, path: null, position }))

  const pathDependedMessages: UnreadMessage[] = filteredByAppearancesCount
    .filter(({ path }) => path === route)
    .map(({ id, path, position }) => ({ id, path, position }))

  return [...onEnterMessages, ...pathDependedMessages]
}
