import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { IconWireFrame } from '@/icon/IconWireFrame'
import { DropdownType } from '../types'
import { Text } from './Text'
import { Icon } from './Icon'

interface FieldProps<T> {
  id: T
  text: string
  icon?: string
  height: number
  type: DropdownType
  selectedId?: T
  hidePopup: () => void
  isNoSign?: boolean
  onSelect?: (id: T) => void
}

export function Field<T>({ id, text, type, height, selectedId, onSelect, hidePopup, icon, isNoSign }: FieldProps<T>) {
  const { theme } = useSelector(whiteLabelSelector)
  const signStrokeWidth = type === 'big' || type === 'bigPictured' ? 2 : 1.5
  const Sign = IconWireFrame({ icon: 'Check', color: theme.brand_color, size: 24 })

  const fieldSelectHandler = useCallback(() => {
    if (onSelect) onSelect(id)

    hidePopup()
  }, [hidePopup, id, onSelect])

  return (
    <Container type={type} onClick={fieldSelectHandler} height={height}>
      {(type === 'bigPictured' || type === 'smallPictured') && (
        <Icon $type={type} src={icon} alt={`${text} image`} fit="contain" />
      )}
      <Text $isSelected={selectedId === id} $type={type}>
        {text}
      </Text>
      {selectedId === id && !isNoSign && <SignContainer $strokeWidth={signStrokeWidth}>{Sign}</SignContainer>}
    </Container>
  )
}

const Container = styled.div<{ type: DropdownType; isSelected?: boolean; height: number }>`
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  position: relative;
  z-index: 0;
  flex-shrink: 0;
  height: ${({ height }) => height}px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-color: #fff;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    &:after {
      background-color: ${({ theme }) => theme.brand_color};
    }
  }

  ${({ type }) => {
    switch (type) {
      case 'small':
      case 'smallPictured':
        return css`
          padding: 0 12px 0 16px;
        `
      case 'big':
      case 'bigPictured':
        return css`
          padding: 0 10px 0 20px;
        `
      case 'simple':
        return css`
          justify-content: center;
        `
      default:
        return null
    }
  }}
`

const SignContainer = styled.div<{ $strokeWidth: number }>`
  margin-left: 16px;
  display: flex;
  height: 100%;
  align-items: center;

  & svg {
    stroke-width: ${({ $strokeWidth }) => $strokeWidth}px;
  }
`
