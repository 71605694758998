export const mediaQuery = {
  thin: 374,
  mobile: 1024,
  // @deprecated should be used for layout only
  smallDesktop: 1440,
}

export const mqThin = `(max-width: ${mediaQuery.thin}px)`
export const mqMobile = `(max-width: ${mediaQuery.mobile}px)`
export const mqSmallDesktop = `(max-width: ${mediaQuery.smallDesktop}px)`

export const thinMedia = `@media ${mqThin}`
export const mobileMedia = `@media ${mqMobile}`
// @deprecated should be used for layout only
export const smallDesktopMedia = `@media ${mqSmallDesktop}`

export const mqDesktop = `(min-width: ${mediaQuery.mobile + 1}px)`
export const mqDesktopLarge = `(min-width: ${mediaQuery.smallDesktop + 1}px)`

export const desktopMedia = `@media ${mqDesktop}`
export const desktopLargeMedia = `@media ${mqDesktopLarge}`

export const mediaNotHover = `@media (hover: none)`
export const mediaHover = `@media (hover: hover)`

export const fontFamilyMono = `font-family: 'Roboto Mono', monospace`

export const colors = {
  white: '#fff',
  black: '#050505',
  green: '#06991b',
  red: '#db162f',
  gray: '#67616a',
  grayLight: '#bfbfbf',
  grayLightest: '#f1f3f7'
} as const
