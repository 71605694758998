import { ReactNode } from 'react'
import { useGetRestrictionsData } from '@/restrictions/useGetRestrictionsData'
import { LoginStatusMessage } from '@/LoginStatusMessage'
import LoginLayout from '~/components/login/LoginLayout'
import { RestrictionView } from '@/restrictions/RestrictionView'

interface Props {
  children: ReactNode
}

export const RestrictionsGuard = ({ children }: Props) => {
  const { restrictionsIsLoaded, restrictionIsActive } = useGetRestrictionsData('overall')

  if (!restrictionsIsLoaded) {
    return <LoginLayout content={<LoginStatusMessage status="dataLoading" />} />
  }

  if (restrictionIsActive) {
    return <LoginLayout content={<RestrictionView section="overall" />} />
  }

  return children
}
