import { createApi } from '@reduxjs/toolkit/query/react'
import { createAxiosRTQuery } from '~/lib/api/createAxiosRTQuery'
import { Profile } from '~/models'
import { gatewayApiQuery, getGatewayApiUrlFromQueryApi, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { getFetchConfigParamsData } from './utils/getFetchConfigParamsData'

const axiosRTQuery = createAxiosRTQuery()

export const profileApi = createApi({
  reducerPath: 'profile',
  baseQuery: axiosRTQuery({
    baseUrl: '/00000001/v2',
  }),
  endpoints: (build) => ({
    getProfile: build.query<Profile, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        // todo: gateway-api returns more data, ensure that all that data is needed
        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/aol/profile/${profile_mnemocode}`,
              method: 'GET',
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/profile`,
              method: 'GET',
            })

        const resError = res.error as { status: number; data: unknown }
        if (resError) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as { status: string; data: Profile }
        const { data } = resData

        return { data }
      },
    }),
    // This updates profile first_visited_at and last_visited_at
    updateVisitDates: build.mutation<boolean, void>({
      async queryFn(_args, queryApi, _extraOptions, fetchWithBaseQuery) {
        const { profile_mnemocode, company } = getFetchConfigParamsData(queryApi)

        const res = !shouldUseGatewayApi(company)
          ? await fetchWithBaseQuery({
              url: `/microservice/metadata/contact_profiles/profile/visit`,
              method: 'PUT',
              params: {
                profile_code: profile_mnemocode,
              },
              data: {
                profile_code: profile_mnemocode,
              },
            })
          : await gatewayApiQuery({
              url: `${getGatewayApiUrlFromQueryApi(queryApi)}/client/v0/visit`,
              method: 'PUT',
            })

        const resError = res.error as { status: number; data: unknown }
        const error = resError?.status
        if (error) return { error: 'Something in API went wrong' }

        const resData = res.data as unknown as { firstVisit: boolean }
        const isFirstVisit = resData?.firstVisit ?? false

        return { data: isFirstVisit }
      },
    }),
  }),
})

export const { useGetProfileQuery, useUpdateVisitDatesMutation } = profileApi
