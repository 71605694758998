import * as Sentry from '@sentry/browser'

export class AuthToken {
  private static isSetEmptyTokenErrorReportedOnce = false

  static set(token: string, source: string) {
    localStorage.setItem('token', token)

    // TODO с обновлениями Sentry и Next.js необходимо перепроверить, не прошла ли ошибка.
    /*
      Без проверки process.env.NODE_ENV === 'production' воркер вылетает со странной ошибкой (window is not defined),
      похоже связанной с работой webpack в dev режиме
    */
    if (!token && !this.isSetEmptyTokenErrorReportedOnce && process.env.NODE_ENV === 'production') {
      this.isSetEmptyTokenErrorReportedOnce = true

      Sentry.captureException(new Error(`Auth token is empty in set. Source: ${source}`))
    }
  }

  static get() {
    const token = localStorage.getItem('token')

    return token
  }

  static remove() {
    localStorage.removeItem('token')
  }
}
