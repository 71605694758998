import { useMemo } from 'react'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { ActionCreatorsMapObject, bindActionCreators, configureStore } from '@reduxjs/toolkit'
import { load, save } from '~/lib/localStorage'
import rootReducer from './rootReducer'
import { apiMiddleware } from './apiMiddleware'

const loadedState = load('@lmg/store')

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: loadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
    }).concat(apiMiddleware),
})

store.subscribe(() => {
  const state = store.getState()
  const { authentication } = state
  save('@lmg/store', { authentication })
})

export type RootState = ReturnType<typeof rootReducer>

export default store

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useActionCreators = <Actions extends ActionCreatorsMapObject>(actions: Actions) => {
  const dispatch = useAppDispatch()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => bindActionCreators(actions, dispatch), [])
}
