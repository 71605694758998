import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useGetOptionById } from '~/hooks/useGetOptionById'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { colors } from '~/lib/theme'
import { getOftenPeriod } from '~/lib/utils/getOftenPeriod'
import { getDateIntl } from '~/lib/utils/getDateIntl'
import { ProductModel, ProgramModel } from '~/models'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useIntl } from 'react-intl'
import { useGetCurrentLocale } from '~/lib/intl/useGetCurrentLocale'
import { getIconExtension } from '@/icon/IconProgramFromStatic'
import { ChangedItemsElement } from './ChangedItemsElement'

interface Props {
  product: ProductModel
  selectedProgramId: string
  selectedOptionId: number
  currentOptionIsDefault: boolean
}

export function ChangedItems({ product, selectedProgramId, selectedOptionId, currentOptionIsDefault }: Props) {
  const intl = useIntl()
  const { presentProgram, presentOption, futureOption, features } = product
  const showPrograms = features.includes('programs')
  const whitelabel = useSelector(whiteLabelSelector)
  const { theme } = whitelabel
  const locale = useGetCurrentLocale()
  const nextMonthName = getDateIntl({ inputDate: getOftenPeriod('nextMonth'), outputFormat: 'MMMM', locale })

  const changedOption = useGetOptionById(selectedOptionId)
  const changedProgram = useGetPrograms(selectedProgramId) as ProgramModel

  const presentOptionIsZero = !presentOption?.visible
  const hasPresentProgram = Boolean(presentProgram)
  const shouldNotShowBoth = presentOptionIsZero && !hasPresentProgram

  const currentOption = futureOption?.optionId ? futureOption : presentOption
  const isOptionChanged = Boolean(currentOption) && currentOption?.optionId !== selectedOptionId

  const dateTo = currentOptionIsDefault
    ? `${intl.messages[`app.misc.will_be`] as unknown as string}`
    : `${intl.messages[`app.misc.from_s_lo_case`] as unknown as string} 1 ${nextMonthName}`

  return (
    <Container>
      <Dates>
        {!shouldNotShowBoth && (
          <Date fontWeight={500} color={colors.black}>
            {intl.messages[`app.misc.now`] as unknown as string}
          </Date>
        )}
        <Date fontWeight={700} color={theme.brand_color} isCentered={shouldNotShowBoth}>
          {dateTo}
        </Date>
      </Dates>
      {isOptionChanged && (
        <ChangedItemsElement
          pictFrom={getOptionIcon(presentOption?.mnemocode)}
          pictTo={getOptionIcon(changedOption?.mnemocode)}
          textFrom={intl.messages[`app.options.${presentOption?.mnemocode}.name`] as unknown as string}
          textTo={intl.messages[`app.options.${changedOption?.mnemocode}.name`] as unknown as string}
          shouldNotShowBoth={shouldNotShowBoth}
          shouldNotShowThis={presentOptionIsZero}
        />
      )}
      {showPrograms && (
        <ChangedItemsElement
          pictFrom={presentProgram?.details.icon}
          pictTo={changedProgram?.details.icon}
          textFrom={presentProgram?.details.name}
          textTo={changedProgram?.details.name}
          shouldNotShowBoth={shouldNotShowBoth}
          shouldNotShowThis={false}
        />
      )}
    </Container>
  )
}

function getOptionIcon(mnemocode?: string) {
  if (!mnemocode) return ''

  return `/options-icons-rect/${mnemocode}.${getIconExtension(mnemocode)}`
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 260px;
  align-self: center;
`

const Dates = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 28px;
  justify-content: center;
`

const Date = styled.span<{ color: string; fontWeight: number; isCentered?: boolean }>`
  font-size: 14px;
  line-height: 16px;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ isCentered }) => isCentered && 'center'};

  &:first-child {
    width: 132px;
  }
  &:last-child {
    width: 128px;
  }
`
