import styled from 'styled-components'
import { ProductModel } from '~/models'
import { Image } from '@/Image'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useIntl } from 'react-intl'

interface Props {
  product: ProductModel
}

export function CardShortInfo({ product }: Props) {
  const intl = useIntl()
  const { bankSpecificFeatures } = useSelector(whiteLabelSelector)
  const { entryName, details } = product
  const { icon, cardName } = details

  const productNameFinal = bankSpecificFeatures.includes('hasOnlyOneCard') ? cardName : entryName


  return (
    <Container>
      <Text>{intl.messages[`app.program.for_card`] as unknown as string}:</Text>
      <CardBlock>
        <CardLogo src={icon} alt="" fit="contain" />
        <CardText>{productNameFinal}</CardText>
      </CardBlock>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-weight: 700;
  font-size: 14px;
  min-height: 20px;
`

const Text = styled.span`
  margin-right: 20px;
`

const CardBlock = styled.div`
  display: flex;
  align-items: center;
`

const CardText = styled.span`
  text-transform: uppercase;
`

const CardLogo = styled(Image)`
  width: 24px;
  height: 16px;
  border-radius: 2px;
  margin-right: 8px;
`
