import { nanoid } from 'nanoid'
import { AxiosRequestConfig, Method } from 'axios'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '~/store/redux-store'
import type {
  AuthenticationState,
  AuthenticationResponse,
  GetUserSettingsParams,
  SetDefaultCardParams,
  LoginWindowResponseArgs,
  AuthenticationData,
  GetUserSettingsResponse,
  SetDefaultCardResponse,
  StageAuthParams,
  OpenLoginWindowParams,
  NewAuthenticationData,
} from '~/models'
import { getAuthProcessParams, getAuthWindowParams, getUUID, strWindowFeatures } from '~/lib/auth'
import { createAxiosInstance } from '~/lib/api/createAxiosInstance'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { AuthToken } from '~/lib/AuthToken'
import { gatewayApiQuery, shouldUseGatewayApi } from '~/lib/gatewayApi'
import { redirectTo } from '~/lib/api/redirectBus'
import * as Sentry from '@sentry/browser'
import { isRejectedAction, isPendingAction } from './common/matchers'
import { GenericResponse } from './common/types'

const defaultConfig = { timeout: 60000 }

const apiClientAuth = createAxiosInstance({ apiType: 'auth', requestConfig: defaultConfig })

const initialState: AuthenticationState = {
  isAuthenticated: false,
  isLoginInProgress: false,
  sessionState: 'authorization',
  sessionToken: '',
  process: {},
  profile: {},
  isLoading: false,
  oauthError: undefined,
}

const AuthApi = {
  getUserSettings: async ({ profileMnemocode }: GetUserSettingsParams) => {
    const response: GenericResponse<{ data: GetUserSettingsResponse }> = await apiClientAuth({
      url: `brothers/api/user/get/settings?profileId=${profileMnemocode}`,
      method: 'GET',
    })

    const { uuid, default_card } = response.data.data

    return {
      uuid,
      default_card,
    }
  },

  setDefaultCard: async ({ cardMnemocode }: SetDefaultCardParams, { getState }: { getState: any }) => {
    const state = getState()
    const {
      authentication: { profile },
    } = state
    const { profile_mnemocode } = profile

    const response: GenericResponse<{ data: SetDefaultCardResponse }> = await apiClientAuth({
      url: `brothers/api/user/set/settings?profileId=${profile_mnemocode}`,
      method: 'PUT',
      data: {
        default_card: cardMnemocode,
      },
    })

    const { default_card } = response.data.data

    return default_card
  },

  openLoginWindow: async (
    { user_token }: OpenLoginWindowParams,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const state = getState() as unknown as RootState
    const { bankSpecificFeatures, oauth_url } = whiteLabelSelector(state)
    const showRedirectUri = bankSpecificFeatures.includes('showRedirectUri')

    const { url, windowName } = getAuthWindowParams(user_token as string, oauth_url as string, showRedirectUri)
    const windowObjectReference = url && windowName ? window.open(url, windowName, strWindowFeatures) : null

    if (windowObjectReference) {
      const { name } = windowObjectReference

      dispatch(
        loginWindowResponse({
          name,
        })
      )
    } else {
      dispatch(
        authenticationActions.oauthFailure({
          error: 'Error: login popup window can not be opened.',
        })
      )
    }
  },

  loginWindowResponse: async ({ name }: { name: string }) => {
    async function windowMessageListener({ source, origin, data }: MessageEvent) {
      const isAuthWindow = origin === window.location.origin && name === (source as Window)?.name

      if (!isAuthWindow) return

      window.removeEventListener<'message'>('message', windowMessageListener)
      const queryParams = getAuthProcessParams(data)
      window.location.href = `/login/authorization?${queryParams}`
    }

    window.addEventListener<'message'>('message', windowMessageListener)
  },

  stageAuthorization: async (
    { client_id, secret }: StageAuthParams,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const request_uuid = getUUID()
    const state = getState() as unknown as RootState
    const { company } = whiteLabelSelector(state)

    const params = {
      client_id,
      secret,
      request_uuid,
    }

    try {
      const response: GenericResponse<AuthenticationResponse> = await apiClientAuth({
        method: 'GET',
        url: '/00000001/v2/auth/stage/token',
        params,
      })
      const { profile_mnemocode, session_token } = response.data

      if (shouldUseGatewayApi(company)) {
        dispatch(newAuthorization({ jwt: session_token }))

        return
      }

      AuthToken.set(session_token, 'stageAuthorization')
      dispatch(getUserSettings({ profileMnemocode: profile_mnemocode }))
      dispatch(authenticationActions.oauthAuthentication(response.data))
    } catch (error) {
      dispatch(
        authenticationActions.oauthFailure({
          error: String(error),
        })
      )
    }
  },

  newAuthorization: async (
    { code, jwt, fallbackParams }: NewAuthenticationData,
    { dispatch, getState }: { dispatch: any; getState: any }
  ) => {
    const state = getState() as RootState
    const { bankMnemocode, gatewayApiUrl } = whiteLabelSelector(state)

    const url = code
      ? `${gatewayApiUrl}/client/v0/client-auth/login`
      : `${gatewayApiUrl}/client/v0/client-auth/login-using-jwt`

    const data = code
      ? {
          code,
          bankMnemocode,
        }
      : {
          jwt,
          bankMnemocode,
        }

    const res = await gatewayApiQuery<{ accessToken: string; clientMnemocode: string }>({
      url,
      method: 'POST',
      data,
    })

    if (res.error) {
      if (code && fallbackParams) {
        localStorage.setItem('is-gateway-api-fallback-enabled', 'true')
        dispatch(oauthAuthorization({ code, ...fallbackParams }))

        let errorMessage = 'not specified'
        if (typeof res.error === 'string') {
          errorMessage = res.error
        } else {
          // @ts-ignore
          errorMessage = res.error.data?.message || res.error.data || ''
        }
        Sentry.captureException(new Error(`gateway-api auth error ${errorMessage}`))
      } else {
        dispatch(
          authenticationActions.oauthFailure({
            error: 'oauthError',
          })
        )

        redirectTo.address = '/login/auth-error'
      }

      return
    }

    // it is safe to use non-null assertion here, because above we check for error and return
    const { accessToken, clientMnemocode } = res.data!

    localStorage.setItem('token-new', accessToken)

    // todo: rename profileMnemocode to clientMnemocode
    dispatch(getUserSettings({ profileMnemocode: clientMnemocode }))
    dispatch(
      authenticationActions.oauthAuthentication({
        session_token: accessToken,
        session_state: 'authorized',
        // todo: rename profile_mnemocode to clientMnemocode
        profile_mnemocode: clientMnemocode,
      })
    )
  },

  oauthAuthorization: async (
    { code, redirect_uri, provider_id, oauth_url }: AuthenticationData,
    { dispatch }: { dispatch: any; getState: any }
  ) => {
    const request_uuid = getUUID()

    const requestConfig = oauth_url
      ? {
          url: oauth_url,
          method: 'GET' as Method,
          params: {
            code,
            redirect_uri,
            provider_id,
            state: nanoid(),
            request_uuid,
          },
        }
      : ({
          url: '/00000001/v2/auth/oauth',
          method: 'POST' as Method,
          data: {
            code,
            redirect_uri,
            provider_id,
            request_uuid,
          },
        } as AxiosRequestConfig)
    try {
      const authResponse: GenericResponse<AuthenticationResponse> = await apiClientAuth(requestConfig)

      const { profile_mnemocode, session_token } = authResponse.data

      AuthToken.set(session_token, 'oauthAuthorization')
      dispatch(getUserSettings({ profileMnemocode: profile_mnemocode }))

      dispatch(authenticationActions.oauthAuthentication(authResponse.data))
    } catch (error) {
      dispatch(
        authenticationActions.oauthFailure({
          error: 'oauthError',
        })
      )
    }
  },
}

export const loginWindowResponse = createAsyncThunk<void, LoginWindowResponseArgs>(
  'authentication/loginWindowResponse',
  AuthApi.loginWindowResponse
)

export const oauthAuthorization = createAsyncThunk<void, AuthenticationData>(
  'authentication/loginWindowResponse',
  AuthApi.oauthAuthorization
)

// todo decide on naming authentication/authorization
export const newAuthorization = createAsyncThunk<void, NewAuthenticationData>(
  'authentication/newAuthorization',
  AuthApi.newAuthorization
)

export const stageAuthorization = createAsyncThunk<void, StageAuthParams>(
  'authentication/stage',
  AuthApi.stageAuthorization
)

export const loginWindowOpen = createAsyncThunk<void, OpenLoginWindowParams>(
  'authentication/loginWindowOpen',
  AuthApi.openLoginWindow
)

export const getUserSettings = createAsyncThunk<GetUserSettingsResponse, GetUserSettingsParams>(
  'authentication/getUserSettings',
  AuthApi.getUserSettings
)

export const setDefaultCard = createAsyncThunk<string, SetDefaultCardParams>(
  'authentication/setDefaultCard',
  AuthApi.setDefaultCard
)

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    oauthAuthentication(state: AuthenticationState, { payload }: PayloadAction<AuthenticationResponse>) {
      state.sessionState = payload.session_state
      state.sessionToken = payload.session_token
      state.profile.profile_mnemocode = payload.profile_mnemocode
      state.isAuthenticated = true
      state.oauthError = undefined
    },
    oauthFailure(state: AuthenticationState, { payload }: PayloadAction<{ error: string }>) {
      state.oauthError = payload.error
      state.isAuthenticated = false
    },
    login(state: AuthenticationState) {
      state.isLoginInProgress = true
    },
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSettings.fulfilled, (state: AuthenticationState, { payload }) => {
        state.profile.uuid = payload.uuid
        state.profile.defaultCard = payload.default_card
        state.isLoading = false
      })
      .addCase(setDefaultCard.fulfilled, (state: AuthenticationState, { payload }) => {
        state.profile.defaultCard = payload
        state.isLoading = false
      })
      .addMatcher(isPendingAction, (state: AuthenticationState) => {
        state.isLoading = true
      })
      .addMatcher(isRejectedAction, (state: AuthenticationState, { payload }) => {
        state.isLoading = false
        state.process.error = true
        state.process.message = payload as string
      })
  },
})

export const authenticationActions = authenticationSlice.actions

export default authenticationSlice.reducer
