import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProcessSlice, Steps, SetProcessPayload } from '~/models/process'

const stepsCollection: Steps = {
  cardSettingsConfirm: [
    { component: 'authorization', title: 'authorization' },
    { component: 'registration', title: 'registration', optional: true },
    { component: 'savingChanges', title: 'saving_changes' },
  ],
  link: [
    { component: 'authorization', title: 'authorization' },
    { component: 'registration', title: 'registration', optional: true },
    { component: 'productsLink', title: 'program_link' },
    { component: 'optionsLink', title: 'options_link' },
    { component: 'savingChanges', title: 'saving_changes' },
  ],
  authorization: [
    { component: 'authorization', title: 'authorization' },
    { component: 'registration', title: 'registration', optional: true },
  ],
}

export const initialState: ProcessSlice = {
  steps: [],
  currentStep: 0,
  direction: 'next',
  selectedProgram: null,
  selectedCard: null,
  selectedProductOption: null,
  process: null,
}

export const slice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    setProcess(state: ProcessSlice, { payload }: PayloadAction<SetProcessPayload>) {
      state.process = payload.process ?? null
      state.selectedProgram = payload.program ?? null
      state.selectedCard = payload.selectedCard ?? null
      state.steps = stepsCollection[payload.process]
      state.selectedProductOption = payload.selectedOption ?? null
    },
    setProductOption(state: ProcessSlice, { payload }: PayloadAction<number>) {
      state.selectedProductOption = payload
    },
    setSelectedProgram(state: ProcessSlice, { payload }: PayloadAction<string>) {
      state.selectedProgram = payload
    },
    resetProcess(state: ProcessSlice) {
      state.process = null
      state.selectedCard = null
      state.steps = []
      state.selectedProductOption = null
      state.currentStep = 0
    },
    setStep(state: ProcessSlice, { payload }: PayloadAction<{ direction: 'prev' | 'next' }>) {
      if (payload.direction === 'next') {
        state.direction = 'next'
        ++state.currentStep
      } else {
        state.direction = 'prev'
        --state.currentStep
      }
    },
    setSelectedCard(state: ProcessSlice, { payload }: PayloadAction<string>) {
      state.selectedCard = payload
    },
  },
})

export default slice.reducer
export const processActions = slice.actions
