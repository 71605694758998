import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { AuthToken } from '~/lib/AuthToken'
import { errorHandlerRegular } from './handlers/errorHandlerRegular'
import { errorHandlerAuth } from './handlers/errorHandlerAuth'
import { errorHandlerVoid } from './handlers/errorHandlerVoid'

export type ApiClientType = 'regular' | 'auth' | 'void'

const errorHandlerMap: Record<ApiClientType, (error: any) => any> = {
  regular: errorHandlerRegular,
  auth: errorHandlerAuth,
  void: errorHandlerVoid,
}

interface Props {
  apiType?: ApiClientType
  requestConfig?: Partial<AxiosRequestConfig>
  token?: string
}

export const createAxiosInstance = ({ apiType = 'regular', requestConfig = {}, token }: Props): AxiosInstance => {
  const defaultConfig = {
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    redirect: 'follow',
    referrer: 'no-referrer',
    responseType: 'json',
    baseURL: '/',
    timeout: 15000,
    headers: {
      mode: 'cors',
    },
  }

  const httpConfig = Object.assign(defaultConfig, requestConfig)
  const axiosInstance = axios.create(httpConfig)

  axiosInstance.interceptors.request.use((config) => {
    const tokenFinal = token ?? AuthToken.get()
    if (tokenFinal) config.headers.authorization = `Bearer ${tokenFinal}`

    return config
  })

  const errorHandler = errorHandlerMap[apiType]

  axiosInstance.interceptors.response.use(undefined, errorHandler)

  return axiosInstance
}
