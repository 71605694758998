import { OptionByProduct } from '~/models'
import { allOptionsSelector, optionsByProductSelector } from '~/store/api/options'
import { useSelector } from 'react-redux'

export function useGetOptionsByProduct(product?: string | null): OptionByProduct[] {
  const options = useSelector(allOptionsSelector)
  const byProduct = useSelector(optionsByProductSelector)
  const optionsByProduct = product ? byProduct[product] : []

  return optionsByProduct.map((optionByProduct) => {
    const option = options.find(({ optionId }) => optionByProduct.option_id === optionId)

    if (!option) throw new Error('The option was not found in the list of all options')

    return {
      ...optionByProduct,
      pluggable: option.pluggable,
      mnemocode: option.mnemocode,
      visible: option.visible,
    }
  })
}
