import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '~/store/redux-store'
import type { WhitelabelConfig, WhitelabelState } from '~/models'

const initialState: WhitelabelState = {
  config: null,
}

const slice = createSlice({
  name: 'whitelabel',
  initialState,
  reducers: {
    setWhitelabelConfig(state: WhitelabelState, { payload }: PayloadAction<WhitelabelConfig>) {
      state.config = payload
    }
  },
})

export default slice.reducer
export const whitelabelActions = slice.actions
export const whiteLabelSelector = (state: RootState): WhitelabelConfig => state.whitelabel.config as WhitelabelConfig
