import { jwtDecode } from 'jwt-decode'

export function getProfileIdFromJwt(token: string) {
  const decoded = jwtDecode<{ user_id: number } | { profileId: string }>(token)

  if ('user_id' in decoded) {
    return String(decoded.user_id)
  }

  return decoded.profileId
}
