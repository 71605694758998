import { createGlobalStyle } from 'styled-components'
import { colors } from '~/lib/theme'

export const GlobalStyle = createGlobalStyle`
  :root {
    color: ${colors.black};
    
    & .swiper-pagination {
      bottom: 0;
      height: 8px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    & .swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.brand_color} !important;
    }
  }
`
