import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Settings, RestrictionType } from '~/models/settings'

const initialState: Settings = {
  historyPath: null,
  isControlsVisible: false,
  selectedOptionId: null,
  selectedProgramId: null,
  cardSettingsMode: 'current',
  currentLanguage: null,
  restrictionsWarningsWasShown: {
    precalc: false,
    conversion: false,
    overall: false,
  },
}

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    selectOptionId(state: Settings, { payload }: PayloadAction<{ id: number }>) {
      state.selectedOptionId = payload.id
    },
    selectProgramId(state: Settings, { payload }: PayloadAction<{ id: string | null }>) {
      state.selectedProgramId = payload.id
    },
    toggleControlsVisibility(state: Settings, { payload }: PayloadAction<boolean>) {
      state.isControlsVisible = payload
    },
    setCardSettingsPeriod(state: Settings, { payload }) {
      state.cardSettingsMode = payload
    },
    setLanguage(state: Settings, { payload }) {
      state.currentLanguage = payload
    },
    setRestrictionWarningOn: (state: Settings, { payload }: PayloadAction<RestrictionType>) => {
      state.restrictionsWarningsWasShown[payload] = true
    },
    setHistoryPath(state: Settings, { payload }: PayloadAction<string | null>) {
      state.historyPath = payload
    },
  },
})

export default slice.reducer
export const settingsActions = slice.actions
