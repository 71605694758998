import { useGetProgramStatusText } from '~/hooks/useGetProgramStatusText'
import { processActions } from '~/store/slices/process'
import { useEffect } from 'react'
import { useGetCurrentOption } from '~/hooks/useGetCurrentOption'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { ModalControlsBlock } from '@/MultiStepModal/components/ModalControlsBlock'
import { useRouter } from 'next/router'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { useGetProductsForProgramLink } from '~/hooks/useGetProductsForProgramLink'
import { useIntl } from 'react-intl'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { Grid } from '@/Grid'
import { ScrollbarWrapper } from '@/ScrollbarWrapper'
import { Product } from './common/Product'
import { Meta } from './common/Meta'
import { Container, SignupNotice, Title } from './common/stylesCommon'

export function ProductsLinkAdd() {
  const intl = useIntl()
  const { setProductOption, setSelectedCard } = useActionCreators(processActions)
  const router = useRouter()
  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)
  const programMnemocode = router.query.program as string
  const program = useGetPrograms(programMnemocode)

  const { details, entryName } = program
  const { icon, name } = details

  const { readyForLinkProducts } = useGetProductsForProgramLink({
    programMnemocode,
  })

  const description = intl.formatMessage({ id: `app.program.product_link.description` }, { name })
  const statusText = useGetProgramStatusText(programMnemocode)
  const isBtnClickable = Boolean(selectedCard)
  const { currentOptionId } = useGetCurrentOption(selectedCard!)

  const manageSelected = (mnemocode: string) => {
    setSelectedCard(mnemocode)
  }

  useEffect(() => {
    if (currentOptionId && selectedCard) {
      setProductOption(currentOptionId)
    }
  }, [currentOptionId, selectedCard, setProductOption])

  return (
    <AnimatedContainer animationKey="ProductsLinkAdd">
      <Meta
        icon={icon}
        title={name}
        rows={[
          {
            param: intl.messages[`app.program.card_name_prefix`] as unknown as string,
            value: entryName || (intl.messages[`app.program.no_entry_name`] as unknown as string),
          },
          {
            param: intl.messages[`app.program.status_prefix`] as unknown as string,
            value: statusText || (intl.messages[`app.program.no_entry_name`] as unknown as string),
          },
        ]}
      />
      <Container gapRow={24}>
        <Title>{intl.messages[`app.program.choose_card_placeholder`] as unknown as string}</Title>
        <ScrollbarWrapper>
          <Grid gapRow={24}>
            {readyForLinkProducts.map(({ entryMnemocode }) => (
              <Product
                key={entryMnemocode}
                selected={selectedCard === entryMnemocode}
                onSelect={manageSelected}
                currentProgramName={name}
                productMnemocode={entryMnemocode}
              />
            ))}
          </Grid>
        </ScrollbarWrapper>
        <SignupNotice>{description}</SignupNotice>
      </Container>
      <ModalControlsBlock disabledNext={!isBtnClickable} noPrev />
    </AnimatedContainer>
  )
}
