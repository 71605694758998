import React from 'react'
import styled from 'styled-components'
import { Divider } from '@/Divider'
import { Image } from '@/Image'
import { TextOverflow } from '@/TextOverflow'

interface MetaInfoProps {
  icon?: string
  title: string
  rows: Row[]
}

export interface Row {
  param: string
  value?: string
}

export function Meta({ icon, title, rows }: MetaInfoProps) {
  return (
    <>
      <Root>
        <MetaIcon src={icon} alt={icon} />
        <MetaDetails>
          <MetaTitle>{title}</MetaTitle>
          {rows.map(
            (row) =>
              row.value && (
                <MetaRow key={row.param}>
                  <MetaParameter>{row.param}</MetaParameter>
                  <MetaValue>{row.value}</MetaValue>
                </MetaRow>
              )
          )}
        </MetaDetails>
      </Root>
      <Divider indent={24} />
    </>
  )
}

const Root = styled.div`
  display: flex;
`

const MetaDetails = styled.div`
  margin-left: 16px;
  max-width: calc(100% - 64px - 16px);
`
const MetaRow = styled.div`
  height: 20px;
  max-width: 100%;
  display: flex;
`
const MetaValue = styled.span<{ linesClamp?: number }>`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  ${TextOverflow};
`
const MetaParameter = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: #67616a;
  padding-right: 0.5em;
  flex-shrink: 0;
`
const MetaTitle = styled.h4<{ linesClamp?: number }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
  ${TextOverflow};
`
const MetaIcon = styled(Image)`
  margin-top: 5px;
  width: 64px;
  height: 40px;
  border-radius: 3px;
`
