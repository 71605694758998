import { ReactNode } from 'react'
import styled from 'styled-components'
import { AnimatePresence } from 'framer-motion'
import { mobileMedia } from '~/lib/theme'
import { useIntl } from 'react-intl'
import { ModalTitle } from '@/Modal'
import { Step } from '~/models'
import { useGetCurrentStep } from './hooks/useGetCurrentStep'

interface MultiStepModalProps {
  title?: string
  getComponent: (step: Step) => ReactNode | null
}

export function MultiStepModal({ getComponent, title }: MultiStepModalProps) {
  const intl = useIntl()
  const { step } = useGetCurrentStep()
  const component = getComponent(step)

  if (!step || !component) return null

  const modalTitle = title ?? (intl.messages[`app.process.${step.title}`] as unknown as string)

  return (
    <Container>
      <ModalTitle dangerouslySetInnerHTML={{ __html: modalTitle }} />
      <Inner>
        <AnimatePresence initial={false}>{component}</AnimatePresence>
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  max-height: 90vh;

  ${mobileMedia} {
    width: 100%;
    max-height: unset;
    height: 100%;
  }
`

const Inner = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  width: 100%;
  min-height: 594px;

  ${mobileMedia} {
    min-height: auto;
  }
`
