import { useSelector } from 'react-redux'
import { useTypedSelector } from '~/store/redux-store'
import { useRouter } from 'next/router'
import { useGetProducts } from '~/hooks/useGetProducts'
import { useGetPrograms } from '~/hooks/useGetPrograms'
import { allOptionsSelector, optionsByProductSelector, optionsByProgramSelector } from '~/store/api/options'
import { ByProduct, ByProductWithMnemocode, Option } from '~/models'

export function useIsConflictOption() {
  const { selectedCard, selectedProgram, selectedProductOption } = useGetProcessState()
  const byProgram = useSelector(optionsByProgramSelector)
  const program = useGetPrograms(selectedProgram!)
  const product = useGetProducts(selectedCard!)
  const programEntryClass = program?.entryClass
  const productEntryClass = product?.entryClass

  const programs = byProgram[programEntryClass] || []
  const existedProductOption = programs.find(
    (currentProgram) =>
      currentProgram?.optionId === selectedProductOption && currentProgram?.productClass === productEntryClass
  )
  if (!selectedProductOption) {
    return false
  }
  return !existedProductOption
}

export function useGetProductOptionSettings(productMnemocode: string) {
  const byProgram = useSelector(optionsByProgramSelector)
  const { selectedProgramId, selectedOptionId } = useGetSettingsSlice()
  const program = useGetPrograms(selectedProgramId!)
  const product = useGetProducts(productMnemocode)
  const programEntryClass = program?.entryClass
  const productEntryClass = product?.entryClass

  const programs = byProgram[programEntryClass]
  const neededProgram = programs?.find(
    (currentProgram) =>
      currentProgram?.optionId === selectedOptionId && currentProgram?.productClass === productEntryClass
  )

  return neededProgram?.product_option_id
}

export function useGetAvailableProgramsForOptionsLink() {
  const byProduct = useSelector(optionsByProductSelector)
  const { selectedCard, selectedProductOption } = useGetProcessState()
  const product = useGetProducts(selectedCard!)
  const productEntryClass = product?.entryClass

  const availableOptions = byProduct[productEntryClass!].filter((option) => option.option_id === selectedProductOption)
  return availableOptions[0]?.programs.map((p) => p.loyalty_code)
}

export function useGetAvailableOptionsForProgramsLink() {
  const byProduct = useSelector(optionsByProductSelector)
  const allOptions = useSelector(allOptionsSelector)
  const { selectedCard, selectedProgram } = useGetProcessState()
  const product = useGetProducts(selectedCard!)
  const program = useGetPrograms(selectedProgram!)
  const programEntryClass = program.entryClass
  const productEntryClass = product.entryClass

  const options = byProduct[productEntryClass!]?.filter((option) =>
    Boolean(option.programs.filter((p) => p.loyalty_code === programEntryClass).length)
  )

  return enhanceProductOptionsByMnemocode(options, allOptions)
}

function enhanceProductOptionsByMnemocode(
  optionsToEnhance: ByProduct[],
  allOptions: Option[]
): ByProductWithMnemocode[] {
  return optionsToEnhance?.map((optionToEnhance) => {
    const currentOption = allOptions.find(({ optionId }) => optionId === optionToEnhance.option_id)

    return { ...optionToEnhance, mnemocode: currentOption!.mnemocode }
  })
}

export function useGetIsOptionConflictForSettings(productMnemocode: string | null = null) {
  const router = useRouter()
  const card = productMnemocode ?? (router.query.card as string)
  const byProgram = useSelector(optionsByProgramSelector)
  const { selectedOptionId, selectedProgramId } = useGetSettingsSlice()

  const program = useGetPrograms(selectedProgramId!)
  const product = useGetProducts(card)
  const programEntryClass = program?.entryClass
  const productEntryClass = product?.entryClass

  if (!selectedOptionId || !selectedProgramId || !product) return { isConflict: false }

  const programs = byProgram[programEntryClass] || []
  const existedProductOption = programs
    ?.filter(Boolean)
    .find(
      (currentProgram) =>
        currentProgram?.optionId === selectedOptionId && currentProgram?.productClass === productEntryClass
    )
  return { isConflict: !existedProductOption, conflictedProgram: selectedProgramId }
}

function useGetProcessState() {
  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)
  const selectedProductOption = useTypedSelector(({ process }) => process.selectedProductOption)

  return { selectedProgram, selectedCard, selectedProductOption }
}

function useGetSettingsSlice() {
  const selectedProgramId = useTypedSelector(({ settings }) => settings.selectedProgramId)
  const selectedOptionId = useTypedSelector(({ settings }) => settings.selectedOptionId)

  return { selectedOptionId, selectedProgramId }
}
