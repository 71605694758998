import {
  ClientOption,
  Entry,
  Option,
  OptionStatus,
  Product,
  ProductEntry,
  ProductModel,
  ProductProgramOptionEntry,
} from '~/models'
import { createSelector } from 'reselect'
import { activeEntriesSelector } from '~/store/api/entries'
import { productsQuerySelector } from '~/store/api/products'
import { allOptionsSelector, clientOptionsSelector } from '~/store/api/options'

export const ProductSelector = createSelector(productsQuerySelector, activeEntriesSelector, (products, entries) => {
  return entries
    .map((entry) => ({
      product: products.find((product) => product?.product_class === entry?.entry_class),
      entry,
    }))
    .filter((pe) => pe.product !== undefined) as unknown as ProductEntry[]
})

export const ProductOptionSelector = createSelector(
  ProductSelector,
  clientOptionsSelector,
  allOptionsSelector,
  addProductOption
)

function addProductOption(
  productEntries: ProductEntry[],
  options: Record<string, ClientOption[]>,
  optionModels: Option[]
): ProductModel[] {
  return productEntries
    .map((productEntry) => {
      const entryOptions = options[productEntry.entry.mnemocode]

      if (!productEntry.entry || !options || !entryOptions) {
        return productModelFactory({
          productEntry,
          pastOption: undefined,
          presentOption: undefined,
          futureOption: undefined,
        })
      }

      // Прошлых опций может быть больше одной, если клиент в первый месяц менял привязку неоднократно,
      // поэтому берем самую позднюю
      const pastOption = entryOptions
        .filter((option) => option.status === OptionStatus.PAST)
        .sort((option, optionToCompare) => (option.startDate < optionToCompare.startDate ? 1 : 0))
      const presentOption = entryOptions.find((option) => option.status === OptionStatus.PRESENT)
      const futureOption = entryOptions.find((option) => option.status === OptionStatus.FUTURE)
      return productModelFactory({
        productEntry,
        pastOption: pastOption.length ? enhanceOptionWithMnemocode(optionModels, pastOption[0]) : undefined,
        presentOption: enhanceOptionWithMnemocode(optionModels, presentOption),
        futureOption: enhanceOptionWithMnemocode(optionModels, futureOption),
      })
    })
    .sort((product, productToCompare) => productToCompare.sortingOrder - product.sortingOrder)
}

function enhanceOptionWithMnemocode(options: Option[], productOption: ClientOption | undefined) {
  if (!productOption) return undefined

  const option = options.find(({ optionId }) => productOption?.optionId === optionId)
  return { ...productOption, mnemocode: option?.mnemocode, visible: option?.visible }
}

function productModelFactory({ productEntry, ...options }: ProductProgramOptionEntry): ProductModel {
  const { entry, product: _product } = productEntry
  const product = enhanceProductFromAttribute20(entry, _product)
  return {
    entryClass: product.product_class,
    details: {
      icon: product.components.logo,
      cardName: product.components.name,
    },
    features: product.features,
    entryName: entry.corpName ?? `${product.components.name} ••\u00A0${String(entry?.name).slice(-4)}`,
    entryStatus: entry.status,
    sortingOrder: entry.entry_class === 'UNCVIPRUB' ? 2 : 1,
    entryMnemocode: entry.mnemocode,
    createdWhen: entry.created_when,
    paymentCardType: entry.paymentCardType,
    parentEntryProduct: entry.parentEntryProduct,
    childrenProducts: entry.childrenProducts,
    ...options,
  }
}

function enhanceProductFromAttribute20(entry: Entry, product: Product) {
  if (!entry || !product) {
    return product
  }

  const enhancedProduct = { ...product }
  if (entry.attribute20) {
    const propertiesToBeEnhanced = product.productProperty
      .filter(
        (productProperty) =>
          productProperty.entryField === 'attribute20' && productProperty.entryValue === entry.attribute20
      )
      .reduce<Record<string, string>>((props, prop) => {
        props[prop.key] = prop.value
        return props
      }, {})

    const productComponents = product.components
    enhancedProduct.components = { ...productComponents, ...propertiesToBeEnhanced }
    return enhancedProduct
  }
  return product
}
