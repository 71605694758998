import styled from 'styled-components'
import { WithChildrenOptional } from '~/models'
import { useIntl } from 'react-intl'

interface Props extends WithChildrenOptional {
  title?: string
  text?: string
}

export function FallbackComponentBase({ children, title, text }: Props) {
  const { messages } = useIntl()

  return (
    <Root>
      <Title
        dangerouslySetInnerHTML={{ __html: title ?? (messages[`app.fallback.error_title`] as unknown as string) }}
      />
      <Text dangerouslySetInnerHTML={{ __html: text ?? (messages[`app.fallback.error_text`] as unknown as string) }} />
      {Boolean(children) && children}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 16px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
`

const Text = styled.div`
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;

  & > p:not(:last-child) {
    margin-bottom: 4px;
  }
`
