import { isBefore } from 'date-fns'

export function filterPeriodsNotBefore(periodsToCheck: string[], periodToCompare: string) {
  return periodsToCheck.filter((period) => !isPeriodBefore(period, periodToCompare))
}

export function isPeriodBefore(periodToCheck: string, periodToCompare: string) {
  return isBefore(new Date(periodToCheck), new Date(periodToCompare))
}

export function getPeriodWhoBefore(periodToCheck: string, periodToCompare: string) {
  return isPeriodBefore(periodToCheck, periodToCompare) ? periodToCompare : periodToCheck
}
