import { useEffect, useState } from 'react'
import { mqMobile, mqThin, mqSmallDesktop, mqDesktop } from '~/lib/theme'

type MQType = 'thin' | 'mobile' | 'smallDesktop' | 'desktop'

const queriesAll: Record<MQType, string> = {
  thin: mqThin,
  mobile: mqMobile,
  smallDesktop: mqSmallDesktop,
  desktop: mqDesktop
}

export function useMediaQuery(currentQuery: MQType = 'mobile') {
  return useCheckQueryMatches(queriesAll[currentQuery])
}

function useCheckQueryMatches(query: string): boolean {
  const getMatches = (q: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(q).matches
    }
    return false
  }

  const [matches, setMatches] = useState<boolean>(getMatches(query))

  function handleChange() {
    setMatches(getMatches(query))
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query)
    handleChange()

    if (matchMedia?.addEventListener) {
      matchMedia.addEventListener('change', handleChange)
    } else {
      matchMedia.addListener(handleChange)
    }

    return () => {
      // @ts-ignore
      if (matchMedia?.addEventListener) {
        matchMedia.removeEventListener('change', handleChange)
      } else {
        matchMedia.removeListener(handleChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  return matches
}
