import { AxiosError } from 'axios'
import { redirectTo } from '../redirectBus'

export const errorHandlerRegular = (error: AxiosError<{ error_code: string }>) => {
  if (error.response && error.response.status === 401) {
    switch (error.response?.data?.error_code) {
      case 'auth.token.expired':
        redirectTo.address = '/login/auth-error?reason=expired'
        break
      default:
        redirectTo.address = '/login/auth-error'
    }
  }

  return Promise.reject(error)
}
