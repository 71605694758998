export function load(key: string) {
  try {
    if (typeof window !== 'undefined') {
      const serializedObj = localStorage.getItem(key)
      if (serializedObj === null) {
        return undefined
      }
      return JSON.parse(serializedObj)
    }
  } catch (error) {
    console.error(error)
  }
}

export function save(key: string, obj: object) {
  try {
    const serializedObj = JSON.stringify(obj)
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, serializedObj)
    }
  } catch (error) {
    console.error(error)
  }
}

export function isExists(key: string) {
  try {
    if (typeof window !== 'undefined') {
      const serializedObj = sessionStorage.getItem(key)
      return serializedObj !== null
    }
  } catch (error) {
    console.error(error)
  }
}

export function remove(key: string) {
  try {
    if (typeof window !== 'undefined') {
      const serializedObj = localStorage.getItem(key)
      if (serializedObj === null) return

      localStorage.removeItem(key)
    }
  } catch (error) {
    console.error(error)
  }
}

export function clear() {
  try {
    if (typeof window !== 'undefined') {
      localStorage.clear()
    }
  } catch (error) {
    console.error(error)
  }
}
