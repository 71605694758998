import styled, { css } from 'styled-components'
import { Image } from '@/Image'
import { DropdownType } from '../types'

export const Icon = styled(Image)<{
  $type: Extract<DropdownType, 'bigPictured' | 'smallPictured'>
  $isDisabled?: boolean
}>`
  border-radius: 4px;
  filter: ${({ $isDisabled }) => $isDisabled && 'grayscale() opacity(0.2)'};

  ${({ $type }) => {
    switch ($type) {
      case 'smallPictured':
        return css`
          width: 51px;
          height: 32px;
          margin-right: 8px;
        `
      case 'bigPictured':
        return css`
          width: 64px;
          height: 40px;
          margin-right: 12px;
        `
      default:
        return null
    }
  }};
`
