import styled, { css } from 'styled-components'
import { ReactNode, useMemo } from 'react'
import { mobileMedia } from '~/lib/theme'
import { nanoid } from 'nanoid'
import { useMediaQuery } from '~/hooks/useMediaQuery'

interface Props {
  columnsCount?: number
  columnsCountMob?: number
  gapColumn?: number
  gapColumnMob?: number
  gapRow?: number
  gapRowMob?: number
  children: ReactNode
  className?: string
  isRow?: boolean
  isSingle?: boolean
  onContainerClick?: () => void
  widthAuto?: boolean
  lastGapSize?: number
  lastGapSizeMob?: number
  isRelative?: boolean
}

export function Grid({
  className,
  children,
  onContainerClick,
  isRow,
  isSingle,
  widthAuto,
  lastGapSize,
  lastGapSizeMob,
  isRelative,
  columnsCount = 1,
  columnsCountMob = columnsCount,
  gapColumn = 0,
  gapColumnMob = gapColumn,
  gapRow = 0,
  gapRowMob = gapRow,
}: Props) {
  const uniqHash = useMemo(() => `-${nanoid(10)}`, [])
  const isMobile = useMediaQuery()
  const isSingleColumnPlacement = !isRow && !isMobile && columnsCount === 1
  const isSingleColumnPlacementMob = !isRow && isMobile && columnsCountMob === 1

  if (isSingle) {
    return (
      <Container
        isSinglePlacement={isSingle}
        className={className}
        onClick={onContainerClick}
        widthAuto={widthAuto}
        isRelative={isRelative}
      >
        {children}
      </Container>
    )
  }

  if (isSingleColumnPlacementMob) {
    return (
      <Container
        className={className}
        gapMob={gapRowMob}
        onClick={onContainerClick}
        widthAuto={widthAuto}
        lastGapSize={lastGapSize}
        lastGapSizeMob={lastGapSizeMob}
        isRelative={isRelative}
      >
        {children}
      </Container>
    )
  }

  if (isSingleColumnPlacement) {
    return (
      <Container
        className={className}
        gap={gapRow}
        onClick={onContainerClick}
        widthAuto={widthAuto}
        lastGapSize={lastGapSize}
        lastGapSizeMob={lastGapSizeMob}
        isRelative={isRelative}
      >
        {children}
      </Container>
    )
  }

  if (isRow) {
    return (
      <Container
        isRow
        className={className}
        onClick={onContainerClick}
        gap={gapColumn}
        gapMob={gapColumnMob}
        widthAuto={widthAuto}
        lastGapSize={lastGapSize}
        lastGapSizeMob={lastGapSizeMob}
        isRelative={isRelative}
      >
        {children}
      </Container>
    )
  }

  return (
    <Container className={className} isRow onClick={onContainerClick} widthAuto={widthAuto} isRelative={isRelative}>
      <Inner
        hash={uniqHash}
        columnsCount={columnsCount}
        columnsCountMob={columnsCountMob}
        gapRow={gapRow}
        gapColumn={gapColumn}
        gapRowMob={gapRowMob}
        gapColumnMob={gapColumnMob}
      >
        {children}
      </Inner>
    </Container>
  )
}

const Container = styled.div<{
  gap?: number
  gapMob?: number
  isRow?: boolean
  widthAuto?: boolean
  isRelative?: boolean
  lastGapSize?: number
  lastGapSizeMob?: number
  isSinglePlacement?: boolean
}>`
  display: flex;
  width: ${({ widthAuto }) => (widthAuto ? 'auto' : '100%')};
  flex-direction: ${({ isRow }) => !isRow && 'column'};
  position: ${({ isRelative }) => isRelative && 'relative'};
  
  & > *:not(:last-child) {
    margin-${({ isRow }) => (isRow ? 'right' : 'bottom')}: ${({ gap }) => gap && `${gap}px`};

    ${mobileMedia} {
      margin-${({ isRow }) => (isRow ? 'right' : 'bottom')}: ${({ gapMob }) => gapMob && `${gapMob}px`};
    }
  }
  
  ${({ lastGapSize, lastGapSizeMob, isRow }) =>
    (lastGapSize || lastGapSizeMob) &&
    css`
      & > *:last-child {
        margin-${isRow ? 'right' : 'bottom'}: ${lastGapSize && `${lastGapSize}px`};
        
        ${mobileMedia} {
          margin-${isRow ? 'right' : 'bottom'}: ${lastGapSizeMob && `${lastGapSizeMob}px`};
        }
      }
    `}

  & > * {
    flex-grow: ${({ isSinglePlacement }) => isSinglePlacement && 1};
    flex-shrink: 0;
  }
`

const Inner = styled.div<Props & { hash: string }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: calc(${({ gapRow }) => gapRow && `${gapRow}px`} * -1);

  ${mobileMedia} {
    margin-bottom: calc(${({ gapRowMob }) => gapRowMob && `${gapRowMob}px`} * -1);
  }

  ${({ gapRow, gapColumn, columnsCount, gapRowMob, gapColumnMob, columnsCountMob }) => css`
    & > div {
      flex-shrink: 0;
      margin-bottom: ${`${gapRow}px`};
      width: calc(100% / ${columnsCount} - ${`${gapColumn}px`} * (${columnsCount} - 1) / 2);

      ${mobileMedia} {
        flex-shrink: 0;
        margin-bottom: ${`${gapRowMob}px`};
        width: calc(100% / ${columnsCountMob} - ${`${gapColumnMob}px`} * (${columnsCountMob} - 1) / 2);
      }
    }
  `}
`
