import { ReactNode, useEffect, useMemo } from 'react'
import { Router } from 'next/router'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { startAnalytics, ym } from './lib/ym'
import { AnalyticsIDContext, type AnalyticsContextProps } from './context'

interface Props {
  children: ReactNode
}

export function YandexMetrikaProvider({ children }: Props) {
  const { analyticsID: analyticsIdPartner } = useSelector(whiteLabelSelector)
  const analyticsIdCommon = Number(process.env.NEXT_PUBLIC_ANALYTICS_ID)

  const analyticsIdContent: AnalyticsContextProps = useMemo(() => {
    return {
      analyticsIdCommon,
      analyticsIdPartner: process.env.NODE_ENV === 'production' && analyticsIdPartner ? analyticsIdPartner : null,
    }
  }, [analyticsIdPartner, analyticsIdCommon])

  useEffect(() => {
    startAnalytics(analyticsIdCommon)
    startAnalytics(analyticsIdPartner)
  }, [analyticsIdCommon, analyticsIdPartner])

  useEffect(() => {
    const handleRouteChange = (url: URL): void => {
      if (analyticsIdCommon) ym(analyticsIdCommon, 'hit', url.toString())
      if (analyticsIdPartner) ym(analyticsIdPartner, 'hit', url.toString())
    }

    Router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [analyticsIdCommon, analyticsIdPartner])

  return <AnalyticsIDContext.Provider value={analyticsIdContent}>{children}</AnalyticsIDContext.Provider>
}
