import { MultiStepModal } from '@/MultiStepModal/MultiStepModal'
import { useSetStepActions } from '@/MultiStepModal/hooks/useSetStepActions'
import { Step } from '~/models'
import { useTypedSelector } from '~/store/redux-store'
import { Authorization } from './components/Authorization'
import { ProductsLinkAdd } from './components/ProductsLinkAdd'
import { SavingChanges } from './components/SavingChanges'
import { Registration } from './components/Registration'
import { OptionsLink } from './components/OptionsLink'

export function ProgramLinkFlow() {
  const selectedCard = useTypedSelector(({ process }) => process.selectedCard)
  const { setNextStep } = useSetStepActions()

  const getComponentFromStep = ({ component }: Step) => {
    switch (component) {
      case 'productsLink':
        return <ProductsLinkAdd />
      case 'authorization':
        return <Authorization onNextClick={setNextStep} />
      case 'registration':
        return <Registration onNextClick={setNextStep} />
      case 'optionsLink':
        return <OptionsLink onlyAvailable skipNoConflict onNextClick={setNextStep} />
      case 'savingChanges':
        return <SavingChanges changedProductId={selectedCard!} />
      default:
        return null
    }
  }

  return <MultiStepModal getComponent={getComponentFromStep} />
}
