import { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import type { WithChildren } from '~/models'

interface Props extends WithChildren {
  onEscKeyPressed?: () => void
}

export function Portal({ onEscKeyPressed, children }: Props) {
  const [modalRoot, setModalRoot] = useState<HTMLDivElement | null>(null)

  const keyPressHandler = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape' && typeof onEscKeyPressed === 'function') {
        onEscKeyPressed()
      }
    },
    [onEscKeyPressed]
  )

  useEffect(() => {
    if (!modalRoot) {
      const div = document.createElement('div')
      div.classList.add('modal-root')
      document.body.appendChild(div)
      setModalRoot(div)
      document.addEventListener('keydown', keyPressHandler)
      return undefined
    }
    return () => {
      if (modalRoot && modalRoot.parentNode === document.body) {
        document.body.removeChild(modalRoot)
      }
      document.removeEventListener('keydown', keyPressHandler)
    }
  }, [keyPressHandler, modalRoot])

  return modalRoot && ReactDOM.createPortal(children, modalRoot)
}
