import { useCallback } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { processActions } from '~/store/slices/process'
import { AnimatedContainer } from '@/MultiStepModal/components/AnimatedContainer'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { Break } from '@/Break'
import { Divider } from '@/Divider'
import { Button } from '@/Button'
import { useGetProducts } from '~/hooks/useGetProducts'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { useIntl } from 'react-intl'
import { ScrollbarWrapper } from '@/ScrollbarWrapper'
import { useMetrika } from '~/hooks/useMetrika'
import { CardShortInfo } from './common/CardShortInfo'
import { ChangedItems } from './common/ChangedItems'
import { useLinkProducts } from '../hooks/useLinkProducts'

export function SavingChanges({
  changedProductId,
  useAnotherStore,
}: {
  changedProductId: string
  useAnotherStore?: boolean
}) {
  const intl = useIntl()
  const { resetProcess } = useActionCreators(processActions)
  const { bankSpecificFeatures } = useSelector(whiteLabelSelector)
  const { linkProducts } = useLinkProducts()
  const product = useGetProducts(changedProductId)
  const { reachGoal } = useMetrika()

  const changeDefaultOptionInstantly = bankSpecificFeatures.includes('cardSettingsChangeDefaultOptionInstantly')
  const optionCreatedByDefault = product?.presentOption?.createdBy === 'SYSTEM'
  const currentOptionIsDefault = changeDefaultOptionInstantly && optionCreatedByDefault

  // TODO Для использования в новом флоу настроек карты
  const selectedProgram = useTypedSelector(({ process }) => process.selectedProgram)
  const selectedProductOption = useTypedSelector(({ process }) => process.selectedProductOption)
  const selectedProgramId = useTypedSelector(({ settings }) => settings.selectedProgramId)
  const selectedOptionId = useTypedSelector(({ settings }) => settings.selectedOptionId)

  const selectedProgramFinal = useAnotherStore ? selectedProgramId : selectedProgram
  const selectedOptionFinal = useAnotherStore ? selectedOptionId : selectedProductOption

  const productEntryClass = product?.entryClass

  const okHandler = useCallback(() => {
    linkProducts(currentOptionIsDefault)
    reachGoal({ target: 'changeCardSettingsApprove', params: { changeCardSettingsApprove_Product: productEntryClass } })
  }, [currentOptionIsDefault, linkProducts, productEntryClass, reachGoal])

  const resetHandler = useCallback(() => {
    resetProcess()
    reachGoal({ target: 'changeCardSettingsReject', params: { changeCardSettingsReject_Product: productEntryClass } })
  }, [productEntryClass, reachGoal, resetProcess])

  return (
    <Container animationKey="SavingChanges">
      <Divider indent={24} direction="bottom" />
      <ScrollbarWrapper>
        <ChangedItems
          product={product}
          selectedProgramId={selectedProgramFinal!}
          selectedOptionId={selectedOptionFinal!}
          currentOptionIsDefault={currentOptionIsDefault}
        />
        <CardShortInfo product={product} />
      </ScrollbarWrapper>
      <Divider indent={24} />
      <ButtonsContainer>
        <Button onClick={okHandler}>{intl.messages[`app.misc.ok_all_right`] as unknown as string}</Button>
        <Break indent={24} />
        <Button onClick={resetHandler} variant="outlined">
          {intl.messages[`app.misc.cancel`] as unknown as string}
        </Button>
      </ButtonsContainer>
    </Container>
  )
}

const Container = styled(AnimatedContainer)`
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
