import styled from 'styled-components'

type Direction = 'top' | 'bottom'

export const Divider = styled.div<{ color?: string; indent?: number; direction?: Direction }>`
  background-color: ${({ color }) => color ?? '#eeeff6'};
  width: 100%;
  height: 1px;
  margin: ${({ indent }) => indent ?? 32}px 0 !important;
  margin-top: ${({ direction }) => direction === 'bottom' && 0} !important;
  margin-bottom: ${({ direction }) => direction === 'top' && 0} !important;
`
