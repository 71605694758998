import { ru, enUS } from 'date-fns/locale'
import { Locale } from 'date-fns'
import { Language } from '~/models/whitelabel'
import { useTypedSelector } from '~/store/redux-store'

const langToLocaleMap: Record<Language, Locale> = {
  'ru-RU': ru,
  'en-US': enUS,
}

export function useGetCurrentLocale() {
  const currentLanguage = useTypedSelector(({ settings }) => settings.currentLanguage)
  const currentLanguageFinal: Language = currentLanguage ?? 'ru-RU'

  return langToLocaleMap[currentLanguageFinal] ?? langToLocaleMap['ru-RU']
}
