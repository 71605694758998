import { useEffect, RefObject, useState, useCallback } from 'react'

export interface Props<T> {
  refPopup: RefObject<T>
}

export function useShowPopup<T extends HTMLElement = HTMLElement>({ refPopup }: Props<T>) {
  const [popupIsVisible, setPopupIsVisible] = useState(false)
  const showPopup = useCallback(() => setPopupIsVisible(true), [])
  const hidePopup = useCallback(() => setPopupIsVisible(false), [])

  const closePopupHandler = useCallback(
    (event: MouseEvent) => {
      const popup = refPopup?.current
      const target = event?.target as Node

      if (Boolean(popup) && popup!.contains(target || null)) return

      hidePopup()
    },
    [hidePopup, refPopup]
  )

  useEffect(() => {
    document.addEventListener(`mousedown`, closePopupHandler)
    return () => {
      document.removeEventListener(`mousedown`, closePopupHandler)
    }
  }, [closePopupHandler])

  return { popupIsVisible, showPopup, hidePopup }
}
