import { useEffect } from 'react'
import { useMetrika } from '~/hooks/useMetrika'

export function useCompatibilityCheckers() {
  const { reachGoal } = useMetrika()

  useEffect(() => {
    reachGoal({
      target: 'browserCompatibility',
      sendGoalTo: 'common',
      params: {
        browserSupportsCSSGrid: CSS.supports('display', 'grid'),
      },
    })
  }, [])
}
