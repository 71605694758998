import { useCallback, useContext } from 'react'
import { AnalyticsIDContext } from '~/components/layout/content/wrappers/yandexMetrika/context'
import type { VisitParameters } from '~/components/layout/content/wrappers/yandexMetrika/lib/types/parameters'
import type { EventParameters } from '~/components/layout/content/wrappers/yandexMetrika/lib/types/events'
import { ym } from '~/components/layout/content/wrappers/yandexMetrika/lib/ym'

type TypeOfGoalStorage = 'common' | 'partner' | 'both'

interface ReachGoalProps {
  target: string
  params?: VisitParameters
  sendGoalTo?: TypeOfGoalStorage
  callback?: () => void
}

export const useMetrika = () => {
  const { analyticsIdCommon, analyticsIdPartner } = useContext(AnalyticsIDContext)

  const reachGoal = useCallback(
    ({ target, params, callback, sendGoalTo = 'both' }: ReachGoalProps) => {
      if (analyticsIdCommon && (sendGoalTo === 'common' || sendGoalTo === 'both')) {
        ym(analyticsIdCommon, 'reachGoal', target, params, callback)
      }
      if (analyticsIdPartner && (sendGoalTo === 'partner' || sendGoalTo === 'both')) {
        ym(analyticsIdPartner, 'reachGoal', target, params, callback)
      }
    },
    [analyticsIdCommon, analyticsIdPartner]
  )

  const ymEvent = useCallback(
    (...parameters: EventParameters) => {
      if (analyticsIdCommon) ym(analyticsIdCommon, ...parameters)
      if (analyticsIdPartner) ym(analyticsIdPartner, ...parameters)
    },
    [analyticsIdCommon, analyticsIdPartner]
  )

  return { reachGoal, ymEvent }
}
