import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useActionCreators, useTypedSelector } from '~/store/redux-store'
import { settingsActions } from '~/store/slices/settings'

export function useHistoryPagePathService() {
  const router = useRouter()
  const { setHistoryPath } = useActionCreators(settingsActions)

  const historyPath = useTypedSelector(({ settings }) => settings.historyPath)
  const route = router.asPath

  const isOnTheSameHistoryPage: boolean = useMemo(() => {
    const routeParts = pathToParts(route)
    const historyPathParts = pathToParts(historyPath)

    return (
      routeParts.length === 2 &&
      historyPathParts.length === 2 &&
      routeParts[0] === historyPathParts[0] &&
      routeParts[1] === historyPathParts[1]
    )
  }, [historyPath, route])

  useEffect(() => {
    if (isOnTheSameHistoryPage) return

    setHistoryPath(null)
  }, [isOnTheSameHistoryPage, setHistoryPath])
}

function pathToParts(path: string | null): string[] {
  if (typeof path !== 'string') return []

  return path.split('/').slice(1, 3)
}
