import { ReactNode, useCallback } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { mobileMedia } from '~/lib/theme'
import { Title } from '@/MobileTitleStyles/Title'
import { useSelector } from 'react-redux'
import { whiteLabelSelector } from '~/store/slices/whitelabel'
import { Burger } from '@/Burger'
import { useMediaQuery } from '~/hooks/useMediaQuery'
import { IconBack } from './IconBack'

interface Props {
  title?: string
  hideBackArrow?: boolean
  onBackBtnClick?: () => void
  className?: string
  additionalBlock?: ReactNode
  isHeaderTag?: boolean
  onBurgerClick?: () => void
}

export function PlainHeader({
  title,
  onBackBtnClick,
  className,
  hideBackArrow,
  additionalBlock,
  isHeaderTag,
  onBurgerClick,
}: Props) {
  const router = useRouter()
  const { bankSpecificFeatures, theme } = useSelector(whiteLabelSelector)
  const hideBurger = bankSpecificFeatures.includes('plainHeaderHideBurger')
  const isMobile = useMediaQuery()

  const headerType = isMobile ? theme.header.type : 'default'

  const routerGoBackAction = useCallback(() => {
    router.back()
  }, [router])
  const handleClickBack = onBackBtnClick ?? routerGoBackAction

  if (!title) return null

  const hasArrow = isMobile && !hideBackArrow

  if (headerType === 'default') {
    return (
      <DefaultContainer className={className} as={isHeaderTag ? 'header' : 'div'}>
        {hasArrow && <IconBack onIconClick={handleClickBack} fillBrandColor />}
        <DefaultTitle $hideBackArrow={!hasArrow}>{title}</DefaultTitle>
        {additionalBlock && !isMobile && <AdditionalBlock>{additionalBlock}</AdditionalBlock>}
      </DefaultContainer>
    )
  }

  return (
    <PlainContainer className={className} as={isHeaderTag ? 'header' : 'div'}>
      <IconBack onIconClick={handleClickBack} />
      <PlainTitle $hasRightIndent={Boolean(isHeaderTag) && hideBurger}>{title}</PlainTitle>
      {!hideBurger && onBurgerClick && <Burger onBurgerClick={onBurgerClick} color="#fff" size={40} />}
    </PlainContainer>
  )
}

const Container = styled.div`
  height: 44px;
  width: 100%;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  ${mobileMedia} {
    background: ${({ theme }) => theme.header.background};
  }
`

const DefaultContainer = styled(Container)`
  margin-bottom: 16px;
`

const DefaultTitle = styled(Title)<{ $hideBackArrow: boolean }>`
  flex-grow: 1;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 ${({ $hideBackArrow }) => !$hideBackArrow && '8px'};
  margin-right: ${({ $hideBackArrow }) => !$hideBackArrow && '28px'};
`

const PlainContainer = styled(Container)`
  padding: 0 16px;
  position: fixed;
  z-index: 100;
`

const PlainTitle = styled.div<{ $hasRightIndent: boolean }>`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 8px;
  font-weight: 600;
  font-size: 17px;
  text-align: center;
  letter-spacing: -0.408px;
  color: #fff;
  margin-right: ${({ $hasRightIndent }) => $hasRightIndent && '28px'};
`

const AdditionalBlock = styled.div`
  margin-left: auto;
  flex-shrink: 0;
`
