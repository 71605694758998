import { MovementDetail } from '~/models/balance'

// eslint-disable-next-line no-shadow
export enum GroupType {
  BonusDirection = 'BONUS_DIRECTION',
  Card = 'CARD',
  GoalData = 'GOAL_DATA',
}

export interface InfoCard {
  cardMnemocode: string
  cardEntryClassName: string
  pan: string
}

export type GoalGroupedBy = 'GOAL_DATA' | 'GOAL'

export interface InfoGoal {
  groupedBy: GoalGroupedBy
  goalGroupId: number
}

interface DetailBase {
  movementDetails: MovementDetail[]
  shown: boolean
}

export type BonusDirectionGroup = 'ACCRUAL' | 'CHARGE_OFF'

export interface DetailBonusDirection extends DetailBase {
  groupName: BonusDirectionGroup
  groupType: GroupType.BonusDirection
  groupDetails: (DetailCard | DetailGoal)[]
}

export interface DetailCard extends DetailBase {
  groupName: 'CARD'
  groupType: GroupType.Card
  info: InfoCard
  groupDetails: DetailGoal[]
}

export interface DetailGoal extends DetailBase {
  groupName: string
  groupType: GroupType.GoalData
  info: InfoGoal
  groupDetails: []
}
