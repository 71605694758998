export type EntryByIdMap = Record<string, Entry>

export type PaymentCardType = 'common' | 'main' | 'additional' | 'virtual'

export interface PaymentCard {
  paymentCardType: PaymentCardType
  parentEntryProduct: string | null
  childrenProducts: string[] | null
  corpName?: string | null
}

export interface Entry extends PaymentCard {
  entry_id: number
  attribute20: string
  entry_class: string
  mnemocode: string
  name: string
  product: string
  product_class: ProductClass
  product_currency: string
  status: PlatformStatus
  created_when: string
}

/* eslint-disable no-shadow */
enum ProductClass {
  LoyaltyProgram = 'LP',
  PaymentCard = 'PC',
  CashBack = 'CB',
}

export enum PlatformStatus {
  InEffect = 'I', // Авторизована
  AwaitingAuthorization = 'A', // Ожидание авторизации
  Cancelled = 'C', // Отмена авторизации
  Error = 'E', // Ошибка
  New = 'N', // Новая
  NotAuthorized = 'M', // Не авторизована
}

export interface EntryDataState {
  byMnemocode: EntryByIdMap
  activeEntriesOrder: string[]
  wasCancelled: EntryByIdMap
  productGroups: Record<string, string>
}

export interface AuthorizeEntryQueryParams {
  entry_nr: string
  entry_class: string
}

export interface ReplaceNumberQueryParams {
  mnemocode: string
  replaceNumber: string
}

export interface RegisterEntryQueryParams {
  entry_class: string
  accept_disclaimers: string[]
}

type MainCardType = 'Y' | 'N'

export interface PaymentCardResponse {
  mnemocode: string
  accountNumber?: string
  mainCard: MainCardType | null
  corpName: string | null
  status: PlatformStatus
}

export type PaymentCardCanDependsType = Exclude<PaymentCardType, 'common' | 'virtual'>

export interface EntryIdList {
  entryIds: number[]
}

export interface EntryGroup {
  groupKey: string
  entryIdList: EntryIdList
}
